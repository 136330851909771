<template lang="pug">
  .agv-ri-contacts
    .cs-telephone
      a(:href="`tel:+7${branch.customer_service_phone}`")
        | {{branch.customer_service_phone | phoneNumber(renter.country)}}
    .cs-schedule
      | {{renter.customer_service_off_days | cpWorkdaysFromOffDays}},
      |
      span.cs-schedule-time
        | {{renter.customer_service_schedule | cpSchedule}}
</template>

<script>
  export default {
    name: 'agv-ri-contacts',
    props: {
      renter: Object,
      branch: Object
    }
  }
</script>

<style scoped>

  .cs-schedule-time {
    white-space: nowrap;
  }
</style>
