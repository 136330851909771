import VueRouter from 'vue-router'
import Vue from 'vue'

import Meta from 'vue-meta'

Vue.use(Meta)

import {sync} from 'vuex-router-sync'

import AgvContentBlock from 'Aggregator/components/agv-content-block'

import PageAgvHome from 'Aggregator/pages/page-agv-home'
import PageAgvList from 'Aggregator/pages/page-agv-list'
import PageAgvRules from 'Aggregator/pages/page-agv-rules'
import PageAgvComparison from 'Aggregator/pages/page-agv-comparison'
import PageAgvArticle from 'Aggregator/pages/page-agv-article'
import PageAgvOrder from 'Aggregator/pages/page-agv-order'

import store from 'Aggregator/store/index'

import AgvCustomerPage from 'Aggregator/components/agv-customer-page'
import PageAgvCustomerTransactionsList from 'Aggregator/pages/page-agv-customer-transactions-list'
import PageAgvCustomerOrdersList from 'Aggregator/pages/page-agv-customer-orders-list'
import PageAgvCustomerDetails from 'Aggregator/pages/page-agv-customer-details'
import PageAgvConsolidatedDelivery from 'Aggregator/pages/page-agv-consolidated-delivery'

import listPage from 'Aggregator/pages/pages/list'
import catalogPage from 'Aggregator/pages/pages/catalog'
import renterPage from 'Aggregator/pages/pages/renter'
import searchPage from 'Aggregator/pages/pages/search'
import adBannerPage from 'Aggregator/pages/pages/ad_banner'
import PageAgvRenterHome from "Aggregator/pages/page-agv-renter-home.vue";

function maybeConvertToNumber(value) {
  return value.match(/^\d+$/) ? parseInt(value) : value
}

function parseQuery(query, paramNames) {
  return _.fromPairs(_.map(paramNames, paramName => {
      return [
        paramName,
        query[paramName] ? _.map(query[paramName].split('+'), maybeConvertToNumber) : []
      ]
    })
  )
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: () => {
        const townId = Vue.cookie.get('town_id')
        return `/${townId}/`
      }
    },
    {
      path: '/:town_id(\\d+)/',
      component: AgvContentBlock,
      props: true,
      children: [
        {
          path: '',
          component: PageAgvHome,
          props: true
        },
        {
          path: 'search/:searchTerms',
          component: PageAgvList,
          props: (route) => {
            return _.merge({page: searchPage}, route.params, parseQuery(route.query, ['colors']))
          }
        },
        {
          path: 'category/:category_id/:kind_id',
          component: PageAgvList,
          props: (route) => {
            return _.merge({page: catalogPage}, route.params, parseQuery(route.query, ['tag_ids', 'colors']), {
              fromSearch: route.query.fromSearch === 'true'
            })
          }
        },
        {
          path: 'renter/:renter_id(\\d+)/',
          component: PageAgvRenterHome
        },
        {
          path: 'renter/:renter_id(\\d+)/category/:category_id/:kind_id',
          component: PageAgvList,
          props: (route) => {
            return _.merge({page: renterPage}, route.params, parseQuery(route.query, ['tag_ids', 'colors']))
          }
        },
        {
          path: 'ad_banner/:ad_banner_id(\\d+)/',
          redirect: to => {
            debugger
            return { path: `/${to.params.town_id}/ab/${to.params.ad_banner_id}/`, query: to.query };
          }
        },
        {
          path: 'ab/:ad_banner_id(\\d+)/',
          component: PageAgvList,
          props: (route) => {
            return _.merge({page: adBannerPage}, route.params, parseQuery(route.query, ['kind_ids', 'colors']))
          }
        },
        {
          path: 'renter/:renter_id(\\d+)/rules',
          component: PageAgvRules,
          props: true
        },
        {
          path: 'favorites',
          component: PageAgvList,
          props: (route) => {
            return _.merge({page: listPage}, route.params, parseQuery(route.query, ['kind_ids', 'colors']), {
              isFavoritesPage: true
            })
          }
        },
        {
          path: 'liked',
          component: PageAgvList,
          props: (route) => {
            return _.merge({page: listPage}, route.params, parseQuery(route.query, ['kind_ids', 'colors']), {
              isLikedPage: true
            })
          }
        },
        {
          path: 'new',
          component: PageAgvList,
          props: (route) => {
            return _.merge({page: listPage}, route.params, parseQuery(route.query, ['kind_ids', 'colors']), {
              isNewArticlesPage: true
            })
          }
        },
        {
          path: 'all',
          component: PageAgvList,
          props: (route) => {
            return _.merge({page: listPage}, route.params, parseQuery(route.query, ['kind_ids', 'colors']))
          }
        },
        {
          path: 'compare',
          component: PageAgvComparison,
          props: true
        },
        {
          path: 'renter/:renter_id(\\d+)/article/:article_id(\\d+)',
          component: PageAgvArticle,
          props: true
        },
        {
          path: 'renter/:renter_id(\\d+)/order/:order_id(\\d+|new)',
          component: PageAgvOrder,
          props: true
        },
        {
          path: '/customer',
          component: AgvCustomerPage,
          children: [
            {
              path: 'transactions/:renter_id(\\d+)',
              component: PageAgvCustomerTransactionsList,
              props: true
            },
            {
              name: 'new-consolidated-delivery',
              path: 'consolidated-delivery/new',
              component: PageAgvConsolidatedDelivery,
              props: true
            },
            {
              path: 'consolidated-delivery/:aggregator_delivery_id',
              component: PageAgvConsolidatedDelivery,
              props: true
            },
            {
              path: 'orders',
              component: PageAgvCustomerOrdersList,
              props: true
            },
            {
              path: 'details',
              component: PageAgvCustomerDetails,
              props: true
            }
          ]
        }
      ]
    },
    {
      path: '*',
      redirect: () => {
        const townId = Vue.cookie.get('town_id')
        return `/${townId}/`
      }
    }
  ],
  scrollBehavior(to, from) {
    if (to.hash) {
      return { selector: to.hash };
    }
    if (to.path.replace(/\?.+$/, '') === from.path.replace(/\?.+$/, '')) {
      // if routes differ only in query string
      return
    }
    return {x: 0, y: 0}
  }
})

router.onError(error => {
  console.log(error.name)
  if (error.name === 'NavigationDuplicated') {
    // console.error(error);
  }
});

sync(store, router)

export default router
