import Vue from 'vue'
import VueCookie from 'vue-cookie'

import _ from 'lodash'
import axios from 'axios'
import qs from 'querystring'

Vue.use(VueCookie)

let statePromisesByRenterId = {}

export default {
  fetchAvailableKindIds(store, townId) {
    return axios.get(`/api/aggregator_kinds/in_town/${townId}`)
      .then(({data: kindCountsById}) => {
          store.commit('setKindCountsById', {townId, kindCountsById})
        }
      )
  },
  loadArticleThumbnailsByRenterId(store) {
    const articlesByRenterId = _.mapValues(
      store.state.order.ordersByRenterId,
      (order) => _.map(order.line_items, 'article_id').join('+')
    )

    return axios.get(
      `/api/renters/article_thumbnails_by_renter_id.json?${qs.stringify(articlesByRenterId)}`
    ).then(({data: articleThumbnails}) => {
        store.commit('setArticleThumbnails', articleThumbnails)
      }
    )
  },
  loadRenterState(store, {renterId, townId}) {
    const alreadyLoadedState = store.state.stateByRenterId[renterId]
    if (alreadyLoadedState) {
      return Promise.resolve()
    }

    if (!statePromisesByRenterId[renterId]) {
      statePromisesByRenterId[renterId] = axios.get(
        `/api/renters/${renterId}/state.json`
      ).then(({data: state}) => {
        store.commit('setRenterState', {renterState: state, renterId})
        store.commit('setRenter', {renterId, renter: state.renter})
        const branch = _.find(state.branches, b => b.town_id === townId)
        _.each(state.articles, (a) => {
          a.total_available = branch.available_total[a.id]
          a.price_in_town = Math.round(a.price * branch.rent_price_multiplier)
        })
        store.commit('articles/add', _.map(state.articles, a => _.merge({renter_id: renterId}, a)))
      })
    }

    return statePromisesByRenterId[renterId]
  }
}
