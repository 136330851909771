import dayjs from 'dayjs'

const dbDate = (date) => {
  if (!date) {
    throw "Must provide date"
  }
  return dayjs(date).format('YYYY-MM-DD')
}

export function getAvailabilityUrl(acquisition, returning, orderId, renterId, townId) {
  return `/api/renters/${renterId}/branches/${townId}/available_for/${dbDate(acquisition)}/${dbDate(returning)}/${orderId || 'new'}`
}

export function getSingleArticleAvailabilityUrl(acquisition, returning, articleId, renterId, townId) {
  return `/api/articles/available_for/${townId}/${renterId}/${articleId}/${dbDate(acquisition)}/${dbDate(returning)}`
}
