/* eslint no-console: 0 */
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import VueRouter from 'vue-router'
import vClickOutside from 'v-click-outside'
import VueImg from 'Aggregator/components/agv-gallery'
import VueHotkey from 'v-hotkey'
import numeral from 'numeral'

import infiniteScroll from 'vue-infinite-scroll'
import BackToTop from 'vue-backtotop'

import VueProgressBar from 'vue-progressbar'

Vue.use(infiniteScroll)
Vue.use(BackToTop)

import * as Sentry from '@sentry/browser'
import {Vue as SentryVueIntegration} from '@sentry/integrations'
import App from 'Aggregator/aggregator.vue'

import 'Aggregator/filters.es6'

import {
  Alert,
  Aside,
  Autocomplete,
  Badge,
  Button,
  Carousel,
  CarouselItem,
  Checkbox,
  Container,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Form,
  FormItem,
  Image,
  Input,
  InputNumber,
  Loading,
  Popover,
  Radio,
  RadioGroup,
  RadioButton,
  Select,
  OptionGroup,
  Option,
  Switch,
  TabPane,
  Tabs,
  TimeSelect
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'
import store from 'Aggregator/store/index'
import moment from 'moment'
import {SENTRY_IGNORED_ERRORS} from 'Shared/lib/sentry'

moment.locale('ru')

Vue.config.productionTip = false

Vue.use(VueCookie)
Vue.use(VueRouter)
Vue.use(vClickOutside)
Vue.use(VueImg)
Vue.use(VueHotkey)

Vue.use(VueProgressBar, {
  color: '#4990E2',
  failedColor: 'red',
  height: '2px'
})

if (window.environment === 'production') {
  Sentry.init({
    dsn: 'https://bdb38ccb8f114a9dad750085b39a394b@sentry.io/1432236',
    environment: window.environment,
    release: window.RELEASE_NAME,
    captureUnhandledRejections: false,
    ignoreErrors: SENTRY_IGNORED_ERRORS,
    integrations: [new SentryVueIntegration({
      Vue,
      attachProps: true
    })]
  })
}

Vue.use(Loading)
Vue.component(Alert.name, Alert)
Vue.component(Aside.name, Aside)
Vue.component(Badge.name, Badge)
Vue.component(Button.name, Button)
Vue.component(Container.name, Container)
Vue.component(DatePicker.name, DatePicker)
Vue.component(Carousel.name, Carousel)
Vue.component(CarouselItem.name, CarouselItem)
Vue.component(Dialog.name, Dialog)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Input.name, Input)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Autocomplete.name, Autocomplete)
Vue.component(Image.name, Image)
Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)
Vue.component(Popover.name, Popover)
Vue.component(Radio.name, Radio)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(RadioButton.name, RadioButton)
Vue.component(TabPane.name, TabPane)
Vue.component(Tabs.name, Tabs)
Vue.component(TimeSelect.name, TimeSelect)
Vue.component(Select.name, Select)
Vue.component(OptionGroup.name, OptionGroup)
Vue.component(Option.name, Option)
Vue.component(Switch.name, Switch)

locale.use(lang)

numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  }
})

numeral.locale('ru')
store.commit('order/setCurrentFromRawLocalStorage')

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app',
    store,
    render: h => h(App)
  })
})
