<template lang="pug">
  .content(v-show='isDataLoaded')
    .page-heading
      h1.page-title.page-title_top
        | Сравнение изделий
      el-button.btn-remove-from-comparison(type="text" @click="clearComparison")
        icon-remove-from-comparison.icon-remove-from-comparison
        | Очистить список сравнения
    .comparison-container
      .overlay-right
      .table-container
        table
          tr
            td.article-image-container(v-for="article in articles" )
              router-link(:to="`/${town_id}/renter/${article.renter_id}/article/${article.id}`")
                img.article__image(:src="`https://pifakit.ru${article.preview_url}`")
          tr
            td(v-for="article in articles")
              .article__name
                router-link(:to="`/${town_id}/renter/${article.renter_id}/article/${article.id}`")
                  | {{article.name | capitalize}}
          tr
            td(v-for="article in articles")
              .article__size
                | {{ article | articleSizes }} см
          tr.row-with-small-top-padding
            td(v-for="article in articles")
              .article__price(:class="{'is-best': isBestRentPriceWithDiscount(article)}")
                span
                  | {{rentPriceWithDiscountFor(article) | money}}
              .article__price_without-discount
                span
                  | {{rentPriceWithoutDiscountFor(article) | money}} для частников
              .rent-price-explanation(:class="{'is-best': isBestBaseRentDuration(article)}")
                span
                  | за {{(article.base_rent_duration || article.renter.base_rent_duration) | decline(["день", "дня", "дней"])}} аренды
              .article__deposit(:class="{'is-best': isBestDepositRule(article.renter)}")
                span(v-if="article.renter.deposit_requirement === 2")
                  | Берется залог
                  br
                  | со всех клиентов
                span(v-else-if="article.renter.deposit_requirement === 1")
                  | Берется залог
                  br
                  | с частных клиентов
                span(v-else)
                  | Без залога
          tr.subheading-row
            td(:colspan="articles.length")
              .subheading
                | Доступно
                .filler
          tr
            td(v-for="article in articles")
              .article__available(:class="{'is-best': isBestAvailable(article)}")
                span
                  | {{availableFor(article)}} шт.
          tr.row-with-top-padding
            td(v-for="article in articles")
              .article__renter-name-and-rating
                router-link.renter-name(:to="`/${town_id}/renter/${article.renter.id}`")
                  span
                    | {{article.renter.short_commercial_name}}
                agv-rating(:rating="article.renter.avg_rating" :rating-count="article.renter.rating_count")
          tr.subheading-row
            td(:colspan="articles.length")
              .subheading
                | Легковая доставка
                .filler
          tr
            td(v-for="article in articles")
              .article__delivery
                .article__service-price(v-if='article.renter.is_delivery_offered')
                  div(:class="{'is-best': isBestCarDeliveryPriceWithDiscount(article.renter)}")
                    span
                      | {{carDeliveryPriceWithDiscountFor(article.renter) | money}}
                .article__service-price_without-discount(v-if='article.renter.is_delivery_offered')
                  div
                    span
                      | {{carDeliveryPriceWithoutDiscountFor(article.renter) | money}} для частников
                .article__service-price(v-else)
                  | —
          tr.subheading-row
            td(:colspan="articles.length")
              .subheading
                | Грузовая доставка
                .filler
          tr
            td(v-for="article in articles")
              .article__delivery
                .article__service-price(v-if='article.renter.is_delivery_offered')
                  div(:class="{'is-best': isBestVanDeliveryPriceWithDiscount(article.renter)}")
                    span
                      | {{vanDeliveryPriceWithDiscountFor(article.renter) | money}}
                .article__service-price_without-discount(v-if='article.renter.is_delivery_offered')
                  div
                    span
                      | {{vanDeliveryPriceWithoutDiscountFor(article.renter) | money}} для частников
                .article__service-price(v-else)
                  | —
          tr.subheading-row
            td(:colspan="articles.length")
              .subheading
                | Оплата
                .filler
          tr
            td(v-for="article in articles")
              agv-ri-payment-methods(:renter="article.renter")
          tr.row-with-small-top-padding
            td(v-for="article in articles")
              .article__renter-link
                agv-add-to-order-button(:article="article"
                :order="orderFor(article.renter_id)"
                :available="availableFor(article)"
                :renter-id="article.renter_id")
          tr
            td(v-for="article in articles")
              .article__cp-address-and-schedule
                agv-ri-cp(:branch="article.branch")
          tr.row-with-small-top-padding
            td(v-for="article in articles")
              .article__contacts
                agv-ri-contacts(:renter="article.renter" :branch="article.branch")
          tr
            td(v-for="article in articles")
              .remove-btn-container
                el-button.btn-remove-from-comparison(type="text" @click="removeArticleFromComparison(article)")
                  icon-remove-from-comparison.icon-remove-from-comparison
                  span(v-if="articles.length > 1")
                    | Удалить из сравнения
                  span(v-else)
                    | Очистить сравнение
</template>

<script>
  import $ from 'jquery'
  import axios from 'axios'
  import _ from 'lodash'

  import Vue from 'vue'

  import NamedArticleSizes from 'Shared/NamedArticleSizes'
  import AgvArticleSize from 'Aggregator/components/agv-article-size'
  import AgvRating from 'Aggregator/components/agv-rating'
  import AgvRiDiscount from 'Aggregator/components/renter-info/agv-ri-discount'
  import AgvRiDelivery from 'Aggregator/components/renter-info/agv-ri-delivery'
  import AgvRiPaymentMethods from 'Aggregator/components/renter-info/agv-ri-payment-methods'
  import AgvRiContacts from 'Aggregator/components/renter-info/agv-ri-contacts'
  import AgvRiCp from 'Aggregator/components/renter-info/agv-ri-cp'

  import IconRemoveFromComparison from 'Aggregator/sprites/icon-remove-from-comparison.svg'
  import AgvAddToOrderButton from 'Aggregator/components/agv-add-to-order-button'
  import {getAvailableForPeriodOrTotal} from 'Shared/availabilityUtilites'

  export default {
    name: 'page-agv-comparison',
    props: {
      town_id: String
    },
    components: {
      AgvAddToOrderButton,
      AgvRiCp,
      AgvRiContacts,
      AgvRiPaymentMethods,
      AgvRiDelivery,
      AgvRiDiscount,
      AgvRating,
      AgvArticleSize,
      IconRemoveFromComparison
    },
    data() {
      return {
        isDataLoaded: false,
        articles: []
      }
    },
    computed: {
      articlesPath() {
        return `/api/articles/by_ids?${$.param({ids: this.$store.state.comparedArticleIdsByRenter})}`
      }
    },
    methods: {
      orderFor(renterId) {
        return this.$store.getters['order/getByRenterId'](renterId)
      },
      availableFor(article) {
        return getAvailableForPeriodOrTotal(article, article.renter_id, this.$store)
      },
      vanDeliveryPriceWithoutDiscountFor(renter) {
        return renter.intratown_van_delivery_price
      },
      vanDeliveryPriceWithDiscountFor(renter) {
        return Math.round(this.vanDeliveryPriceWithoutDiscountFor(renter) * (1 - renter.service_discount))
      },
      carDeliveryPriceWithoutDiscountFor(renter) {
        return renter.intratown_car_delivery_price
      },
      carDeliveryPriceWithDiscountFor(renter) {
        return Math.round(this.carDeliveryPriceWithoutDiscountFor(renter) * (1 - renter.service_discount))
      },
      rentPriceWithoutDiscountFor(article) {
        return article.price_in_town
      },
      rentPriceWithDiscountFor(article) {
        return Math.round(this.rentPriceWithoutDiscountFor(article) * (1 - article.renter.rent_discount))
      },
      isBestAvailable(article) {
        const allValues = _.map(this.articles, a => this.availableFor(a))
        const maxAvailable = _.max(allValues)
        return _.uniq(allValues).length > 1 && this.availableFor(article) === maxAvailable
      },
      isBestCarDeliveryPriceWithDiscount(renter) {
        const allValues = _.map(this.articles, a => this.carDeliveryPriceWithDiscountFor(a.renter))
        const bestCarDeliveryPrice = _.min(allValues)
        return _.uniq(allValues).length > 1 && this.carDeliveryPriceWithDiscountFor(renter) === bestCarDeliveryPrice
      },
      isBestVanDeliveryPriceWithDiscount(renter) {
        const allValues = _.map(this.articles, a => this.vanDeliveryPriceWithDiscountFor(a.renter))
        const bestVanDeliveryPrice = _.min(allValues)
        return _.uniq(allValues).length > 1 && this.vanDeliveryPriceWithDiscountFor(renter) === bestVanDeliveryPrice
      },
      isBestBaseRentDuration(article) {
        const allValues = _.map(this.articles, a => a.base_rent_duration || a.renter.base_rent_duration)
        const bestBaseRentDuration = _.max(allValues)
        return _.uniq(allValues).length > 1 && (
            (article.base_rent_duration || article.renter.base_rent_duration) === bestBaseRentDuration
        )
      },
      isBestDepositRule(renter) {
        const allValues = _.map(this.articles, a => a.renter.deposit_requirement)
        return _.uniq(allValues).length > 1 && renter.deposit_requirement === 0
      },
      isBestRentPriceWithDiscount(article) {
        const allValues = _.map(this.articles, a => this.rentPriceWithDiscountFor(a))
        const bestPrice = _.min(allValues)
        return _.uniq(allValues).length > 1 && this.rentPriceWithDiscountFor(article) === bestPrice
      },
      removeArticleFromComparison(article) {
        this.$store.commit('removeArticleFromComparison', {articleId: article.id, renterId: article.renter.id})
        Vue.set(this, 'articles', _.without(this.articles, article))
        if (_.isEmpty(this.articles)) {
          this.$router.push(`/${this.town_id}/`)
        }
      },
      clearComparison() {
        this.$store.commit('clearComparison')
        this.$router.push(`/${this.town_id}/`)
      },
      fetchData() {
        if (!this.articlesPath) {
          return
        }

        this.articles = []

        this.isDataLoaded = false

        var rentersById
        var branchesByRenterId

        const renterIds = _.keys(this.$store.state.comparedArticleIdsByRenter)

        axios.get(`/api/renters/by_ids?${$.param({ids: renterIds})}`).then(
          ({data: renters}) => {
            rentersById = _.keyBy(renters, 'id')
            axios.get(`/api/branches/${this.town_id}/by_renter_ids?${$.param({renter_ids: renterIds})}`).then(
              ({data: branches}) => {
                branchesByRenterId = _.keyBy(branches, 'renter_id')
              }).then(() => {
              axios.get(this.articlesPath).then(({data: articles}) => {
                _.each(articles, a => {
                  a.renter = rentersById[a.renter_id]
                  const storedRenter = this.$store.state.rentersById[a.renter_id]
                  a.renter.avg_rating = storedRenter.avg_rating
                  a.renter.rating_count = storedRenter.rating_count
                  a.branch = branchesByRenterId[a.renter_id]
                  Object.assign(a, new NamedArticleSizes(a).value)
                })
                this.articles = articles
                this.isDataLoaded = true
              })
            })
          }
        )
      },
    },
    created() {
      this.fetchData()
    }
  }
</script>

<style scoped>
  table {
    border-spacing: 65px 0;
    border-collapse: separate;
    margin-left: -65px;
  }

  td {
    max-width: 220px;
    font-size: 14px;
    line-height: 1.5em;
  }

  td {
    padding: 10px 0;
  }

  .article__price {
    margin-top: 13px;
    margin-bottom: 20px;
    font-size: 32px;
    display: flex;
    justify-content: stretch;
    align-items: baseline;
  }

  .article__price_without-discount {
    margin-bottom: 10px;
  }

  .article__service-price, .article__available {
    font-size: 22px;
    margin-bottom: 14px;
  }

  .remove-btn-container {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .btn-remove-from-comparison {
    color: grey;
    font-weight: normal;
  }

  .btn-remove-from-comparison >>> path {
    stroke: grey;
  }

  .icon-remove-from-comparison {
    position: relative;
    bottom: -4px;
    margin-right: 3px;
  }

  .article__name {
    font-size: 14px;
  }

  .article__image {
    max-width: 75%;
    max-height: 150px;
    padding-bottom: 20px;
  }

  .article__renter-name-and-rating a {
    color: black;
  }

  .article__renter-name-and-rating a:hover {
    color: #0074d9;
  }

  .article__cp-address-and-schedule {
    margin-top: 10px;
  }

  .subheading-row td {
    color: grey;
    padding-top: 40px;
    /*border-bottom: 1px solid lightgray;*/
    padding-bottom: 5px;
  }

  .subheading {
    display: flex;
  }

  .filler {
    border-bottom: 1px solid lightgray;
    position: relative;
    top: -10px;
    flex-grow: 1;
    margin-left: 15px;
  }

  .subheading-row td {
    padding-right: 0;
  }

  td:last-child {
    padding-right: 0;
  }

  .row-with-top-padding td {
    padding-top: 40px;
  }

  .row-with-small-top-padding td {
    padding-top: 20px;
  }

  .content >>> .agv-rating div {
    display: inline-block;
  }

  .article-image-container {
    padding: 0;
  }

  .article__name a {
    color: black;
  }

  .article__name a:hover {
    color: #4A90E2;
  }

  .is-best {
    position: relative;
  }

  .is-best::before {
    /*position: absolute;*/
    /*left: -15px;*/
    /*content: '▶︎';*/
    /*font-size: 10px;*/
    /*color: #5ccc16;*/
    /*display: inline-block;*/
  }

  .is-best span {
    color: black;
    text-shadow: 6px 0px 6px #b8ffa8,
    0px 1px 6px #b8ffa8,
    -6px 0px 6px #b8ffa8,
    0px -1px 6px #b8ffa8;
  }

  .comparison-container {
    position: relative;
  }

  .table-container {
    overflow-x: auto;
  }

  .overlay-right {
    z-index: 100;
    right: 0;
    width: 100px;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%);
    pointer-events: none;
  }

  .rent-price-explanation {
    margin-bottom: 10px;
  }

  .content >>> .cp-schedule {
    color: gray;
  }

  .content >>> .cs-schedule {
    color: gray;
  }

  .renter-name {
    font-size: 18px;
    display: inline-block;
    margin-bottom: 10px;
  }
</style>
