<template lang="pug">
  .agv-town-info(v-if="branchInfo && town")
    a.renter-dashboard-enter-link(v-if="isLoggedInToDashboard" :href="memberAddress" target="_blank" )
      | Кабинет прокатчика
    a.renter-dashboard-enter-link(v-else href="/signup" target="_blank" )
      | Разместить свои изделия
    span.town-name
      | В {{town.prepositional_name}}
      | {{branchInfo.renters_count | decline(['прокатчик', 'прокатчика', 'прокатчиков'])}},
      | {{branchInfo.stocks_count | decline(['изделие', 'изделия', 'изделий'])}}
</template>

<script>
  export default {
    name: 'agv-town-info',
    props: {
      town: Object,
      branchInfo: Object,
      isLoggedInToDashboard: Boolean
    },
    computed: {
      memberAddress() {
        return window.memberAddress
      }
    }
  }
</script>

<style scoped>
  .agv-town-info {
    font-size: 12px;
    color: #7e7e7e;
  }

  .renter-dashboard-enter-link {
    margin-right: 20px;
    color: #7e7e7e;
    border-bottom: 1px solid #ddd;
  }

  .town-name {
    text-decoration: none !important;
  }
</style>
