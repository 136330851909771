<template lang="pug">
  .agv-ri-delivery
    div(v-if='renter.is_delivery_offered')
      div
        div(v-if='renter.uses_cars')
          | {{renter.intratown_car_delivery_price | money(renter.country)}} — легковая
      div(v-if='renter.uses_vans')
        | {{renter.intratown_van_delivery_price | money(renter.country)}} — грузовая
      div(v-else-if='renter.uses_trucks')
        | {{renter.intratown_truck_delivery_price | money(renter.country)}} — грузовая
    div(v-else)
      | Не предоставляется
</template>

<script>
  export default {
    name: 'agv-ri-delivery',
    props: {
      renter: Object
    },
    computed: {
      countryCurrencySymbol() {
        return this.$options.filters.currencySymbol(this.renter.country)
      },
    }
  }
</script>

<style scoped>

</style>
