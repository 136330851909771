<template lang="pug">
  .agv-ri-payment-methods
    .payment-method(v-if="renter.is_payment_via_modulbank_allowed")
      | Картой онлайн
    .payment-method(v-if="renter.is_payment_via_robokassa_allowed")
      | Картой или электронными деньгами
    .payment-method(v-if="renter.is_payment_via_sberbank_allowed")
      | Переводом на карту
    .payment-method(v-if="renter.is_payment_via_wire_transfer_allowed")
      | Банковским переводом
    .payment-method(v-if="renter.is_payment_via_cash_allowed")
      | Наличными
</template>

<script>
  export default {
    name: 'agv-ri-payment-methods',
    props: {
      renter: Object
    }
  }
</script>

<style scoped>

</style>
