
const a = ['.ac', '.ad', '.ae', '.af', '.ag', '.ai', '.al', '.am', '.an', '.ao', '.aq', '.ar', '.as', '.at', '.au', '.aw', '.ax', '.az'];
const b = ['.ba', '.bb', '.bd', '.be', '.bf', '.bg', '.bh', '.bi', '.bj', '.bm', '.bn', '.bo', '.br', '.bs', '.bt', '.bv', '.bw', '.by', '.bz'];
const c = ['.ca', '.cc', '.cd', '.cf', '.cg', '.ch', '.ci', '.ck', '.cl', '.cm', '.cn', '.co', '.cr', '.cs', '.cu', '.cv', '.cx', '.cy', '.cz'];
const d = ['.dd', '.de', '.dj', '.dk', '.dm', '.do', '.dz'];
const e = ['.ec', '.ee', '.eg', '.eh', '.er', '.es', '.et', '.eu'];
const f = ['.fi', '.fj', '.fk', '.fm', '.fo', '.fr'];
const g = ['.ga', '.gb', '.gd', '.ge', '.gf', '.gg', '.gh', '.gi', '.gl', '.gm', '.gn', '.gp', '.gq', '.gr', '.gs', '.gt', '.gu', '.gw', '.gy'];
const h = ['.hk', '.hm', '.hn', '.hr', '.ht', '.hu'];
const i = ['.id', '.ie', '.il', '.im', '.in', '.io', '.iq', '.ir', '.is', '.it'];
const j = ['.je', '.jm', '.jo', '.jp'];
const k = ['.ke', '.kg', '.kh', '.ki', '.km', '.kn', '.kp', '.kr', '.kw', '.ky', '.kz'];
const l = ['.la', '.lb', '.lc', '.li', '.lk', '.lr', '.ls', '.lt', '.lu', '.lv', '.ly'];
const m = ['.ma', '.mc', '.md', '.me', '.mg', '.mh', '.mk', '.ml', '.mm', '.mn', '.mo', '.mp', '.mq', '.mr', '.ms', '.mt', '.mu', '.mv', '.mw', '.mx', '.my', '.mz'];
const n = ['.na', '.nc', '.ne', '.nf', '.ng', '.ni', '.nl', '.no', '.np', '.nr', '.nu', '.nz'];
const o = ['.om'];
const p = ['.pa', '.pe', '.pf', '.pg', '.ph', '.pk', '.pl', '.pm', '.pn', '.pr', '.ps', '.pt', '.pw', '.py'];
const q = ['.qa'];
const r = ['.re', '.ro', '.rs', '.ru', '.rw'];
const s = ['.sa', '.sb', '.sc', '.sd', '.se', '.sg', '.sh', '.si', '.sj', '.sk', '.sl', '.sm', '.sn', '.so', '.sr', '.ss', '.st', '.su', '.sv', '.sy', '.sz'];
const t = ['.tc', '.td', '.tf', '.tg', '.th', '.tj', '.tk', '.tl', '.tm', '.tn', '.to', '.tp', '.tr', '.tt', '.tv', '.tw', '.tz'];
const u = ['.ua', '.ug', '.uk', '.us', '.uy', '.uz'];
const v = ['.va', '.vc', '.ve', '.vg', '.vi', '.vn', '.vu'];
const w = ['.wf', '.ws'];
const y = ['.ye', '.yt', '.yu'];
const z = ['.za', '.zm', '.zw'];

const TWO_LETTER_TLDS = a.concat(b).concat(c).concat(d).concat(e).concat(f).concat(g).concat(h).concat(i).concat(j).concat(k).concat(l).concat(m).concat(n).concat(o).concat(p).concat(q).concat(r).concat(s).concat(t).concat(u).concat(v).concat(w).concat(y).concat(z);
const DOMAINS_REGEX = TWO_LETTER_TLDS.map((domain) => domain.replace('.', '')).join('|')

export const EMAIL_REGEX = new RegExp(`.+@.+\\.(\\w{3,}|${DOMAINS_REGEX})$`)
