<template lang="pug">
  .content
    .page-heading
      .page-title.page-title_top
        | Условия работы {{renter.short_commercial_name_cases[1]}}
    agv-rules(:renter="renter" :branch="branch" :get-loss-prices-url="getLossPricesUrl")
</template>

<script>
import store from 'Aggregator/store/index'
import AgvRules from "Aggregator/components/agv-rules"

export default {
  name: "page-agv-rules",
  components: {AgvRules},
  props: {
    renter_id: String,
    town_id: String
  },
  methods: {
    getLossPricesUrl(renterId) {
      return `/api/renters/${renterId}/loss_prices.pdf`
    }
  },
  computed: {
    currentRenterState() {
      return _.get(this.$store.state.stateByRenterId, this.renter_id)
    },
    renter() {
      return this.$store.state.rentersById[this.renter_id]
    },
    branch() {
      return _.find(this.currentRenterState.branches, b => b.town_id === parseInt(this.town_id))
    }
  },
  beforeRouteEnter(to, fr, next) {
    store.dispatch('loadRenterState', {
      renterId: parseInt(to.params.renter_id),
      townId: parseInt(to.params.town_id)
    }).then((result) => {
      next()
    })
  },
}
</script>

<style scoped>

</style>
