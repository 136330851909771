import _ from 'lodash'

import Vue from 'vue'

import {urlFor} from 'Aggregator/shared/navSheet'
import {getNavCategories} from 'Aggregator/lib/store/navCategories'

export default {
  townId: state => {
    return state.route.params.town_id
  },
  town: state => {
    return state.town.townsById[state.route.params.town_id]
  },
  getRenterCustomerByRenterId: state => renterId => {
    const agCustomer = state.customer.current
    if (!(agCustomer && agCustomer.customers_by_renter_id)) {
      return null
    }

    return agCustomer.customers_by_renter_id[renterId]
  },
  navCategories: state => {
    const availableKindIds = state.availableKindIdsByTownId[state.route.params.town_id]
    if (!_.get(availableKindIds, 'length')) {
      return []
    }
    return getNavCategories(state.categories, state.kinds, availableKindIds)
  },
  firstKindPath: state => {
    const townIdForSitewideUrls = state.route.params.town_id || Vue.cookie.get('town_id')
    const availableKindIds = state.availableKindIdsByTownId[townIdForSitewideUrls]
    if (!_.get(availableKindIds, 'length')) {
      return '/'
    }
    const navCategories = getNavCategories(state.categories, state.kinds, availableKindIds)
    const firstCategory = _.orderBy(navCategories, 'position')[0]
    return urlFor(_.orderBy(firstCategory.kinds, 'position')[0], townIdForSitewideUrls)
  },
  anyComparedArticles: state => {
    return !_.isEmpty(state.comparedArticleIdsByRenter)
  },
  branch: state => {
    return _.find(window.branches, b => b.town_id === state.town.current.id)
  },
  acquisition: state => {
    if (!state.range) {
      return null
    }
    return state.range[0]
  },
  returning: state => {
    if (!state.range) {
      return null
    }
    return state.range[1]
  },
  getArticleNameSubstitutions: state => renterId => {
    if (renterId === window.chiavariId || renterId === window.servirovkaId) {
      return null
    }

    return {
      'Кьявари': 'Тиффани'
    }
  },
}
