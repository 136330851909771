import Vue from 'vue'
import _ from 'lodash'
import ArticleSize from 'Shared/ArticleSize'

class AggregatorArticle {
  get url() {
    return `/${this.town_id}/renter/${this.renter_id}/article/${this.id}`
  }
  get size() {
    return new ArticleSize(this).value
  }
}

export default function toAggregatorArticles(articles, townId) {
  let kindsById = _.keyBy(window.kinds, 'id')

  for (let a of articles) {
    Object.setPrototypeOf(a, new AggregatorArticle)
    let kind = kindsById[a.aggregator_kind_id]

    if (!a.town_id) {
      Vue.set(a, 'town_id', townId)
    }
    Vue.set(a, 'kind_position', _.get(kind, 'compound_position'))
    Vue.set(a, 'kind_name', _.get(kind, 'name'))
    Vue.set(a, 'has_aggregator_suitability', Number(a.aggregator_suitability !== null))
    Vue.set(a, 'usefulness', a.size * a.total_available)
    let adPositionsNumber = Number(!!a.position_in_kind) + Number(!!a.position_in_category)
    if (adPositionsNumber > 0)
      Vue.set(a, 'adPriority', (a.position_in_kind || 0 + a.position_in_category || 0) / adPositionsNumber)
  }
}
