<template lang="pug">
  agv-customer-transactions-page-content(v-if="renter"
  :renter="renter"
  :customer="renterCustomer"
  :transactions="transactions"
  :get-orders-route-url="getOrdersRouteUrl"
  :get-customer-bills-url="getCustomerBillsUrl"
  :get-last-wire-transfer-bill-details-url="getLastWireTransferBillDetailsUrl")
    h1.page-title.page-title_top
      | {{renter.short_commercial_name}}: денежные операции
</template>

<script>
  import axios from 'axios'

  import AgvCustomerTransactionsPageContent from 'Aggregator/components/agv-customer-transactions-page-content'
  import {getOrdersRouteUrl, getCustomerBillsUrl, getLastWireTransferBillDetailsUrl} from 'Aggregator/lib/order'
  import eventBus from 'Shared/eventBus'

  export default {
    name: 'page-agv-customer-transactions-list',
    components: {AgvCustomerTransactionsPageContent},
    props: {
      renter_id: String
    },
    data() {
      return {
        renter: null,
        transactions: null,
        getOrdersRouteUrl,
        getCustomerBillsUrl,
        getLastWireTransferBillDetailsUrl
      }
    },
    computed: {
      renterCustomer() {
        return _.merge(
          {},
          this.$store.getters.getRenterCustomerByRenterId(this.renter_id) || {balance: 0},
          this.$store.state.customer.current
        )
      }
    },
    methods: {
      fetchTransactions() {
        axios.get(`/api/renters/${this.renter_id}`).then(({data: renter}) => {
          this.renter = renter
          axios.get(`/api/renters/${this.renter_id}/customer_transactions`)
            .then(({data: transactions}) => {
              this.transactions = transactions
            })
        })
      }
    },
    created() {
      this.fetchTransactions()
      eventBus.$on('renterCustomer:retrieveSurplus', this.fetchTransactions)
    },
    watch: {
      renter_id: [
        {
          handler: 'fetchTransactions'
        }
      ],
    },
  }
</script>

<style scoped>

</style>
