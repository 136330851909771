<template lang="pug">
  .content
    h1.page-title.page-title_top
      span.title(style="display: inline-block; margin-right: 20px;")
        | {{renter.short_commercial_name | capitalize}}
      agv-rating(v-if="renter" :rating="renter.avg_rating" :rating-count="renter.rating_count" style="display: inline-block;")
    .catalog
      div(v-for="{category, kinds} in kindsForListByCategory" style="width: 100%" :key="category.id")
        h2.category-title
          | {{category.name}}
        agv-kind-list(:kinds="kinds" :town-id="town_id" :column-count="4")
    div(v-if="newArticles.length")
      h2.category-title
        | Новинки {{renter.short_commercial_name}}
      .catalog
        agv-article(v-for="article in newArticles"
          :key="article.id"
          :article="article"
          :show-discount="showDiscount"
          :town-id="article.town_id"
          is-in-wide-view
          :renter="renter"
          :show-rating="true"
          :show-like-button="true"
          :show-created-at="true")
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

import AgvArticle from 'Aggregator/components/agv-article'
import AgvKindList from 'Aggregator/components/agv-kind-list'
import AgvRating from "Aggregator/components/agv-rating.vue";

import toAggregatorArticles from 'Aggregator/shared/toAggregatorArticles'

import store from 'Aggregator/store/index'

import imageMapResize from 'image-map-resizer'
import AgvArticlesList from "Aggregator/components/agv-articles-list.vue";

const PRIORITIZED_CATEGORIES = [
  'Мебель',
  'Текстиль',
  'Посуда',
  'Центральные элементы',
  'Архитектура',
  'Освещение'
]

export default {
  name: 'page-agv-renter-home',
  data() {
    return {
      newArticles: [],
      possibleKinds: []
    }
  },
  computed: {
    town_id() {
      return this.$route.params.town_id
    },
    renter() {
      return this.getRenter(this.$route.params.renter_id)
    },
    kindsForListByCategory() {
      const kinds = _.compact(
          _.map(
              this.possibleKinds,
              k => {
                return {
                  id: k.id,
                  name: k.name,
                  count: k.count,
                  url: `/${this.town_id}/renter/${this.$route.params.renter_id}/category/${k.aggregator_category_id}/${k.id}`,
                  image_url: 'https://pifakit.ru' + k.preview_url,
                  aggregator_category_id: k.aggregator_category_id,
                  position: k.position
                }
              }
          )
      )
      const categoriesOrderedByPosition = _.orderBy(
          _.map(
              _.uniq(_.map(kinds, 'aggregator_category_id')),
              categoryId => {
                return this.getCategory(categoryId)
              }
          ),
          'position'
      )

      const prioritizedCategories = _.filter(
          categoriesOrderedByPosition,
          category => PRIORITIZED_CATEGORIES.includes(category.name)
      )

      const nonPrioritizedCategories = _.filter(
          categoriesOrderedByPosition,
          category => !PRIORITIZED_CATEGORIES.includes(category.name)
      )

      return [
        ..._.map(
            prioritizedCategories,
            category => {
              return {
                category,
                kinds: _.orderBy(
                    _.filter(kinds, k => k.aggregator_category_id === category.id),
                    'position'
                )
              }
            }
        ),
        {
          category: {name: 'Другое'},
          kinds: _.orderBy(
              _.flatten(
                  _.map(
                      nonPrioritizedCategories,
                      category => {
                        return _.filter(kinds, k => k.aggregator_category_id === category.id)
                      }
                  )
              ),
              'position'
          )
        }
      ]
    },
    showDiscount() {
      return this.$store.getters.showDiscount
    },
  },
  methods: {
    getRenter(id) {
      return this.$store.state.rentersById[id]
    },
    getCategory(id) {
      return this.$store.state.categoriesById[id]
    }
  },
  components: {AgvArticlesList, AgvArticle, AgvKindList, AgvRating},
  created() {
    axios.get(`/api/articles/${this.town_id}/new_articles?renter_id=${this.$route.params.renter_id}`)
        .then(response => {
          const newArticles = _.take(response.data.articles, 28 * 4)
          toAggregatorArticles(newArticles)
          this.newArticles = newArticles
        })

    axios.get(`/api/renters/${this.$route.params.renter_id}/possible_kind_ids?town_id=${this.town_id}`)
        .then(response => {
              this.possibleKinds = _.map(
                  response.data,
                  k => {
                    const kind = _.find(this.$store.state.kinds, {id: k.aggregator_kind_id})
                    return {
                      ...kind,
                      preview_url: k.preview_url,
                      count: k.count
                    }
                  }
              )
            }
        )
  }
}
</script>

<style scoped>
.content {
  margin-bottom: 0;
}

.content >>> .el-carousel__container {
  height: calc((100vw - 120px) * 0.45);
  min-height: 439px;
}

.catalog {
  display: flex;
  flex-wrap: wrap;
}

.catalog .ag-article {
  flex-basis: 25%;
}

.title {
  //line-height: 42px;
  font-size: 28px;
  display: block;
  //padding-top: 42px;
  margin-bottom: 20px;
}

.category-title {
  line-height: 36px;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding-top: 12px;
  padding-bottom: 12px;
}

.page-title >>> .agv-rating {
  font-size: 12px;
  display: inline-block;
  margin-top: 14px;
  margin-right: 20px;
}

.page-title >>> .agv-star-rating {
  margin-right: 0.4em;
  display: inline-block;
}

.page-title >>> .rating-count {
  font-weight: normal;
  display: inline-block;
}

.page-title {
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 40px;
}
</style>
