<template lang="pug">
  div(v-if="delivery" style="width: 100%" :class="{'non-editable': delivery.is_cancelled || isSavingOrCreating}")
    .page-heading
      h1.page-title.page-title_top
        | Объединенная доставка {{delivery.id ? '№ ' + delivery.id : ''}}
      .state(v-if="delivery.is_cancelled")
        | Отменена
    .section(v-if="delivery.order_ids_by_renter_id")
      .label
        | Заказы
      .edit-box
        table.orders-table
          tr(v-for="order in selectedOrders" :key="order.id")
            td
              el-button(type="text" icon="el-icon-close" @click="removeFromDelivery(order)")
            td
              | {{order.code}} — {{getRenter(order.renter_id).short_commercial_name}} — {{order.total | money('ru')}}
        el-dropdown(v-if="selectableOrders.length" @command="addOrder")
          el-button(icon="el-icon-plus" type="text" v-if="!delivery.is_cancelled")
            | Добавить заказ
          el-dropdown-menu(slot="dropdown")
            el-dropdown-item.dropdown-order-item(v-for="order in selectableOrders" :key="order.id" :command="order")
              | {{order.code}} — {{getRenter(order.renter_id).short_commercial_name}} — {{order.total | money('ru')}}
    .section(style="font-size: 14px;")
      order-delivery-details-form(v-if="selectedOrders.length"
        :order="selectedOrders[0]"
        country="ru"
        :town="$store.getters.town"
        :delivery-details="delivery"
        :errors="errors")
        div(v-if="!delivery.is_cancelled")
          .section
            .label
              | Стоимость
            .edit-box
              | Рассчитывается оператором
              p(style="margin-top: 7px;")
                a(target="_blank" href="https://docs.google.com/document/d/1D1IQ6_f8vy7ptjQDb47yd_eVwYTe4OBdJq_1pLU15hU/edit?usp=sharing")
                  | Посмотреть расценки
          div(v-if="delivery.id")
            el-button.cc-btn-done(type="success" @click="save")
              | Сохранить
            el-button.cc-btn-cancel(type="danger" @click="cancel")
              | Отменить доставку
          el-button.cc-btn-done(v-else type="success" @click="create")
            | Отправить заявку
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import store from 'Aggregator/store/index'

  import {MessageBox} from 'element-ui'

  import OrderDeliveryDetailsForm from 'Shared/components/order-delivery-details-form'
  import {getIsOrderSelectable} from 'Aggregator/lib/order'

  export default {
    name: "page-agv-consolidated-delivery",
    components: {OrderDeliveryDetailsForm},
    props: {
      aggregator_delivery_id: String
    },
    data() {
      return {
        orders: null,
        errors: {},
        delivery: null,
        isSavingOrCreating: false,
      }
    },
    computed: {
      deliveryForm() {
        return _.merge(
          _.cloneDeep(this.delivery),
          {
            acquisition: this.selectedOrders[0].acquisition,
            returning: this.selectedOrders[0].returning,
            distance: this.delivery.distance || 0
          }
        )
      },
      selectedOrders() {
        if (!this.orders) {
          return []
        }

        return _.filter(this.orders, o => {
          const byRenter = this.delivery.order_ids_by_renter_id[o.renter_id]
          if (!byRenter) {
            return false
          }
          return _.includes(byRenter, o.id)
        })
      },
      selectableOrders() {
        return _.filter(
          _.difference(this.orders, this.selectedOrders),
          o => {
            return (
                !o.aggregator_delivery_id ||
                parseInt(this.aggregator_delivery_id) === o.aggregator_delivery_id
              ) &&
              getIsOrderSelectable(o, this.selectedOrders)
          }
        )
      }
    },
    methods: {
      addOrder(order) {
        const ids = this.delivery.order_ids_by_renter_id
        const byRenter = ids[order.renter_id]
        if (!byRenter) {
          Vue.set(ids, order.renter_id, [order.id])
        } else {
          byRenter.push(order.id)
        }
      },
      removeFromDelivery(order) {
        const ids = this.delivery.order_ids_by_renter_id
        const byRenter = ids[order.renter_id]
        byRenter.splice(byRenter.indexOf(order.id), 1)
      },
      fetchOrders() {
        axios.get('/api/orders').then(({data: orders}) => {
          this.orders = orders
        })
      },
      create() {
        this.isSavingOrCreating = true
        axios.post(
          '/api/aggregator_deliveries/',
          this.deliveryForm,
        ).then(({data: delivery}) => {
            this.errors = {}
            MessageBox.alert('Мы позвоним, чтобы согласовать детали доставки.', 'Заявка отправлена', {
              confirmButtonText: 'OK',
              callback: action => {
                this.$router.push('/customer/orders')
              }
            })
          },
          ({response: {data: errors}}) => {
            Vue.set(this, 'errors', errors)
          }
        ).finally(() => {
          this.isSavingOrCreating = false
        })
      },
      save() {
        this.isSavingOrCreating = true
        axios.patch(
          `/api/aggregator_deliveries/${this.delivery.id}`,
          this.deliveryForm
        ).then(({data: delivery}) => {
            delivery.isExtratown = delivery.distance > 0
            this.delivery = delivery
            this.errors = {}
            MessageBox.alert('', 'Изменения сохранены', {
              confirmButtonText: 'OK'
            })
          },
          ({response: {data: errors}}) => {
            Vue.set(this, 'errors', errors)
          }
        ).finally(() => {
          this.isSavingOrCreating = false
        })
      },
      cancel() {
        axios.patch(`/api/aggregator_deliveries/${this.delivery.id}/cancel`)
          .then(({data: delivery}) => {
              delivery.isExtratown = delivery.distance > 0
              this.delivery = delivery
              this.errors = {}
              this.$router.push('/customer/orders')
            }
          )
      },
      onAggregatorDeliveryChange() {
        if (!this.aggregator_delivery_id) {
          const customer = this.$store.state.customer.current
          Vue.set(this, 'delivery', {
            order_ids_by_renter_id: this.$route.params.orderIdsByRenterId,
            distance: 0,
            contact_phone: customer.phone,
            contact_name: customer.first_name + ' ' + customer.last_name,
          })
          return
        }
        axios.get(`/api/aggregator_deliveries/${this.aggregator_delivery_id}.json`)
          .then(({data: delivery}) => {
            Vue.set(this, 'delivery', _.merge(delivery, {isExtratown: delivery.distance > 0}))
          })
      },
      getRenter(id) {
        return this.$store.state.rentersById[id]
      }
    },
    created() {
      this.fetchOrders()
    },
    watch: {
      aggregator_delivery_id: [{
        handler: 'onAggregatorDeliveryChange',
        immediate: true
      }],
      $route(to) {
        if (!to.path.indexOf('new') !== -1) {
          this.aggregator_delivery_id = null
        }
      },
    },
    beforeRouteEnter(to, fr, next) {
      if (store.state.customer.current.id) {
        next()
      } else {
        store.dispatch('customer/load').then(() => {
          next()
        })
      }
    }
  }
</script>

<style scoped>
  .section {
    margin-bottom: 40px;
    display: flex;
  }

  .label {
    width: 200px;
    color: #4A4A4A;
  }

  table {
    margin-bottom: 10px;
    margin-top: -20px;
    margin-left: -2px;
  }

  td {
    padding-right: 5px;
    vertical-align: middle;
    font-size: 14px;
  }

  .rent-period {
    margin-left: 2em;
  }

  .non-editable {
    pointer-events: none;
  }
</style>
