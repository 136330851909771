import {renderMeta} from 'Shared/meta'
import store from 'Aggregator/store/index'

const DEFAULT_TEMPLATES = [
  {
    pattern: '/*/renter/*/article/*',
    title: '{{{article.name}}} в аренду в {{town.prepositional_name}} за {{article.price}} ₽',
    description: '{{article.name}} в аренду за {{article.price}} ₽. {{#article.key_feature_description}}{{article.key_feature_description}}. {{/article.key_feature_description}}Без залога. Профскидка 20%.'
  },
  {
    pattern: '/*/category/*/*/*',
    title: '{{{model.name_with_kind}}} в аренду в {{town.prepositional_name}}',
    description: '{{model.name_with_kind}} в аренду для свадеб, банкетов и других мероприятий. Доставка и монтаж. Профскидка 20%.'
  },
  {
    pattern: '/*/category/*/*',
    title: 'Аренда {{{kind.element_name_many}}} на мероприятие в {{town.prepositional_name}}',
    description: '{{kind.name}} в аренду для свадеб, банкетов и других мероприятий. Доставка и монтаж. Профскидка 20%.'
  },
  {
    pattern: '/*/category/*',
    title: 'Аренда {{{category.element_name_many}}} на мероприятие в {{town.prepositional_name}}',
    description: '{{category.name}} в аренду для свадеб, банкетов и других мероприятий. Доставка и монтаж. Профскидка 20%.'

  },
  {
    pattern: '/*',
    title: 'Аренда стульев, текстиля, декора на мероприятие в {{town.prepositional_name}}',
    description: 'Аренда стульев, текстиля и декора для свадеб, банкетов и других мероприятий. Доставка и монтаж. Профскидка 20%.'
  }
]

function extractTemplates(templates, key) {
  return _.map(templates, (t) => {
    return {pattern: t.pattern, template: t[key]}
  })
}

function regexify(templates) {
  return _.map(templates, t => {
    return {
      regex: new RegExp(t.pattern.replace(/\*/g, '\\d+')),
      template: t.template
    }
  })
}

const TITLE_TEMPLATES = regexify(
  _.concat(
    extractTemplates(_.orderBy(store.state.seo_templates, ['priority'], ['desc']), 'title'),
    extractTemplates(DEFAULT_TEMPLATES, 'title'),
  )
)

const DESCRIPTION_TEMPLATES = regexify(
  _.concat(
    extractTemplates(_.orderBy(store.state.seo_templates, ['priority'], ['desc']), 'description'),
    extractTemplates(DEFAULT_TEMPLATES, 'description'),
  )
)

const templatesByType = {
  title: TITLE_TEMPLATES,
  description: DESCRIPTION_TEMPLATES,
}

export function renderMetaForComponent(component, type) {
  return renderMeta(component.$route.path, templatesByType[type], component.metaState)
}
