<template lang="pug">
  .agv-ad-banner(:class="{'slider-mode': mode === 'slider'}")
    .preview-mode(v-if="mode === 'preview'")
      .preview(v-for="(banner, index) in previewBanners" :key="banner.id")
        .preview-tab
          .is-new(v-if="banner.is_new")
            .is-new-text Новинка
          .renter-name
            | {{banner.renter_short_commercial_name}}
        icon-like-button.preview-like-btn(:is-liked="banner.is_liked" @click="tryToggleLike(banner)")
        router-link.img-link(:to="banner.url" @click="onClickBannerUrl(banner)")
          img(v-bind:src="`https://storage.yandexcloud.net/pifakit-img-vendor/ab/large/${banner.image}.jpg`" @click="onClickBannerUrl(banner)")
    .tab-area(v-else-if="mode === 'tabbed'" :class="edgeClasses" ref="tabArea")
      .tabs
        .tab(v-for="(banner, index) in banners" :key="banner.id" :ref="`tab-${index}`" :class="{'is-active': banner === selectedBanner}" @click="goFromTo(selectedBanner, banner, index)")
          .is-new(v-if="banner.is_new")
            .is-new-text Новинка
          .renter-name
            | {{banner.renter_short_commercial_name}}
            IconCircle.has-impression(v-if="!banner.has_impression")
    .image-area(v-if="selectedBanner && (mode === 'tabbed' || mode === 'slider')")
      icon-like-button.like-btn(:is-liked="selectedBanner.is_liked" @click="tryToggleLike(selectedBanner)")
      button.arrow-button.arrow-left(@click="goToPrevious" v-if="banners.length > 1")
        IconArrowLeft
      router-link(:to="selectedBanner.url")
        img(v-bind:src="`https://storage.yandexcloud.net/pifakit-img-vendor/ab/large/${selectedBanner.image}.jpg`"
          @click="onClickBannerUrl(selectedBanner)")
      button.arrow-button.arrow-right(@click="goToNext" v-if="banners.length > 1")
        IconArrowRight
</template>

<script>
import axios from 'axios';
import {Element} from 'element-ui';

import IconLike from 'Aggregator/sprites/heart.svg'
import IconCircle from 'Aggregator/sprites/circle.svg'
import IconArrowLeft from 'Aggregator/sprites/arrow-left.svg';
import IconArrowRight from 'Aggregator/sprites/arrow-right.svg';

import _ from 'lodash';
import IconLikeButton from "Aggregator/components/icon-like-button.vue";
import eventBus from "Shared/eventBus";

export default {
  name: 'agv-ad-banner',
  props: {
    limit: {
      type: Number
    },
    sliderMode: {
      type: Boolean,
      default: false,
    },
    showOnlyNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      banners: [],
      selectedBanner: null,
      mode: 'tabbed',
      edgeLeft: false,
      edgeRight: true,
    };
  },
  components: {
    IconLikeButton,
    IconLike,
    IconCircle,
    IconArrowLeft,
    IconArrowRight,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['customer/isLoggedIn']
    },
    edgeClasses() {
      return {
        'three-or-less': this.banners.length <= 3,
        'edge-right': this.edgeRight,
        'edge-left': this.edgeLeft,
      };
    },
    previewBanners() {
      return _.take(this.banners, 3);
    },
  },
  methods: {
    checkScroll() {
      const scrollArea = this.$refs.tabArea;
      const maxScroll = scrollArea.scrollWidth - scrollArea.clientWidth;

      this.edgeLeft = scrollArea.scrollLeft > 0;
      this.edgeRight = scrollArea.scrollLeft < maxScroll;
    },
    tryToggleLike(banner) {
      if (!this.isLoggedIn) {
        eventBus.$emit('proceedToLogin')
        return;
      }

      if (banner.is_liked) {
        axios.delete(`/api/ab/${banner.id}/like`)
            .then(response => {
              banner.is_liked = false;
              this.$store.dispatch('customer/load')
            });
      } else {
        axios.post(`/api/ab/${banner.id}/like`)
            .then(response => {
              banner.is_liked = true;
              this.$store.dispatch('customer/load')
            });
      }
    },
    fetchBanners() {
      axios.get(`/api/ab?limit=${this.limit || 15}`, {params: {page_url: this.$route.path, only_new: this.showOnlyNew}})
          .then(response => {
            this.banners = response.data;
            this.selectedBanner = this.banners[0];
            if (this.sliderMode) {
              this.mode = 'slider';
            } else {
              this.mode = !_.every(this.banners, banner => banner.has_impression) ? 'tabbed' : 'preview';
            }
            if (this.banners.length === 0) {
              return;
            }
            if (this.mode === 'preview') {
              this.createDisplays(this.previewBanners);
            } else if (this.mode === 'tabbed' || this.mode === 'slider') {
              this.createDisplays([this.selectedBanner]);
            }
          });
    },
    goToNext() {
      const currentIndex = this.banners.indexOf(this.selectedBanner);
      const newIndex = (currentIndex + 1) % this.banners.length;
      this.goFromTo(
          this.selectedBanner,
          this.banners[newIndex],
          newIndex
      );
    },
    goToPrevious() {
      const currentIndex = this.banners.indexOf(this.selectedBanner);
      const newIndex = (currentIndex - 1 + this.banners.length) % this.banners.length;
      this.goFromTo(
          this.selectedBanner,
          this.banners[newIndex],
          newIndex
      )
    },
    goFromTo(previousBanner, nextBanner, tabIndex) {
      this.createImpression(previousBanner);
      this.selectedBanner = nextBanner;
      this.createImpression(nextBanner);
      this.createDisplays([nextBanner]);

      if (this.mode === 'tabbed') {
        const element = this.$refs[`tab-${tabIndex}`][0];
        element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
      }
    },
    createImpression(banner) {
      axios.post(`/api/ab/${banner.id}/create_impression`)
          .then(response => {
            banner.has_impression = true;
          })
    },
    createDisplays(banners) {
      axios.post(`/api/ab/create_displays`, {ad_banner_ids: banners.map(banner => banner.id)})
          .then(response => {
            banners.forEach(banner => banner.has_display = true);
          })
    },
    onClickBannerUrl(banner) {
      axios.post(`/api/ab/${banner.id}/create_navigation`)
    },
  },
  created() {
    this.fetchBanners();
  },
  mounted() {
    if (this.$refs.tabArea) {
      this.$refs.tabArea.addEventListener('scroll', this.checkScroll);
    }
  },
  beforeDestroy() {
    if (this.$refs.tabArea) {
      this.$refs.tabArea.removeEventListener('scroll', this.checkScroll);
    }
  },
  watch: {
    '$route.path': function () {
      this.fetchBanners();
    },
  },
};

</script>

<style scoped>
.agv-ad-banner {
  grid-column: 1 / -1;
}

.tab-area {
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  white-space: nowrap;
  margin-bottom: -1px;
  z-index: 1;
  position: relative;
}

.tab-area.edge-left {
  border-left: 1px solid #D5D5D5;
}

.tab-area.edge-right {
  border-right: 1px solid #D5D5D5;
}

.tab-area::-webkit-scrollbar { /* For Chrome, Safari, and Opera */
  display: none;
}

.tab-area.three-or-less {
  border: none;
}

.tabs {
  display: flex;
  justify-content: space-between;
  gap: 9px;
}

.tab-area.three-or-less .tabs {
  width: 100%;
  justify-content: center;
}

.tab {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  padding: 11px;
  height: 32px;
  border: 1px solid #D5D5D5;
  border-radius: 11px 11px 0 0;
  font-size: 12px;
  gap: 15px;
  cursor: pointer;
  width: calc((100vw - 570px) / 3.5);
  min-width: 160px;
  max-width: 220px;
  scroll-padding-inline-start: 50px;
  scroll-margin-inline-start: 60px;
  position: relative;
}

.tab:hover {
  border-color: #c8c8c8;
  border-bottom-color: #D5D5D5;
  transition: border-color 0.2s ease-out;
  background: linear-gradient(to bottom, #fdfdfd 0%, #fff 100%);
}

.tab-area.three-or-less .tab {
  flex: 1 1 auto;
  width: unset;
  min-width: unset;
  max-width: unset;
}

.tab.is-active {
  border-bottom-color: #fff;
  transition: border-bottom-color 0s;
  pointer-events: none;
}

.is-new {
  border-radius: 3px;
  background: #B797DF;
  color: #FFF;
  font-size: 9px;
  padding: 2px 4px;
  margin-right: calc(-20px + 9px - 45px);
}

.is-new-text {
  display: inline-block;
}

@media (max-width: 1200px) {
  .is-new {
    display: none;
  }
}

.renter-name {
  flex-grow: 1;
  text-align: center;
  position: relative;
}

.has-impression {
  color: #A8A8A8;
  margin-left: 8px;
  position: absolute;
  top: 4px;
}

.image-area {
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 1px solid #D5D5D5;
  padding-top: 5px;
  display: flex;
  justify-content: center;
}

.agv-ad-banner.slider-mode .image-area {
  padding: 0;
}

.agv-ad-banner.slider-mode .image-area a {
  width: 75%;
}

.image-area img {
  width: 100%;
  aspect-ratio: 16 / 7;
  display: block;
}

.like-btn {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 100;
  transform: scale(1.2);
}

.agv-ad-banner.slider-mode .like-btn {
  right: 14%;
}

.preview-like-btn {
  position: absolute;
  top: 26px;
  right: 2px;
  z-index: 100;
  transform: scale(0.9);
}

.image-area button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  height: 100%;
}

.arrow-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(100% / 7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

@media (max-width: 1200px) {
  .arrow-button {
    display: none;
  }

  .agv-ad-banner.slider-mode .image-area a {
    width: 100%;
  }

  .like-btn, .agv-ad-banner.slider-mode .like-btn {
    right: 14px;
  }
}


.slider-mode .arrow-button {
  opacity: 1;
}

.arrow-button.arrow-left {
  left: -20px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.3), transparent);
}

.arrow-button.arrow-right {
  right: -20px;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.3), transparent);
}

.slider-mode .arrow-button.arrow-left:hover {
  background: linear-gradient(to right, #f8f8f8, transparent);
}

.slider-mode .arrow-button.arrow-right:hover {
  background: linear-gradient(to left, #f8f8f8, transparent);
}

.arrow-button:hover {
  opacity: 1;
}

.preview-mode {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}

.preview {
  border: 1px solid #D5D5D5;
  border-radius: 11px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.preview img {
  width: 100%;
  aspect-ratio: 16 / 7;
  display: block;
}

.preview-tab {
  padding: 6px 11px;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
}

.preview-tab .is-new {
  border-radius: 3px;
  background: #B797DF;
  color: #FFF;
  font-size: 9px;
  padding: 2px 4px;
}

.preview-tab .renter-name {
  text-align: center;
}
</style>
