<template lang="pug">
  .agv-order-select
    a.order-entry(:href="e.url" v-for="e in orderEntries" :key="e.renterId" :class="{active: e.url === $route.path}")
      i.remove-btn.el-icon-delete(v-if="!e.id" @click="remove(e.renterId)")
      .order-header
        | Заказ {{e.code}}
      .renter-name
        | {{e.renterName}}
      .article-photos
        el-image(v-for="url in e.thumbnailUrls" :src="url" :key="url" fit="scale-down")
</template>

<script>
import store from 'Aggregator/store/index'
import eventBus from 'Shared/eventBus'
import {getArticle} from 'Aggregator/lib/order'

function getRenter(id) {
  return store.state.rentersById[id]
}

export default {
  name: "agv-order-select",
  props: {
    ordersByRenterId: Object,
    getArticle: Function
  },
  computed: {
    orderEntries() {
      return _.orderBy(
          _.compact(
              _.map(this.ordersByRenterId, (o, renterId) => {
                try {
                  return {
                    id: o.id,
                    code: o.code,
                    added_at: o.added_at,
                    url: `/${this.$route.params.town_id}/renter/${renterId}/order/${o.id || 'new'}`,
                    renterId: renterId,
                    renterName: getRenter(renterId).short_commercial_name_cases[0],
                    thumbnailUrls: _.map(
                        _.map(o.line_items, li => {
                          return _.get(_.get(this.$store.state.articleThumbnails, renterId), li.article_id) ||
                              _.get(getArticle(li.article_id, renterId), 'thumbnail_url')
                        }),
                        thumbnail_url => thumbnail_url ? thumbnail_url : null
                    ),
                  }
                } catch {
                  return null
                }
              })
          ),
          'added_at'
      )
    }
  },
  methods: {
    remove(renterId) {
      this.$store.commit('order/clearCurrent', {renterId})
      eventBus.$emit('order:cleared')
    }
  }
}
</script>

<style scoped>
.el-image {
  height: 50px;
}

.el-image + .el-image {
  margin-left: 2px;
}

.order-entry {
  display: block;
  padding: 30px 45px 35px;
  outline: 1px solid #ECECEC;
  color: black !important;
  position: relative;
}

.order-entry:hover {
  outline: 3px solid #ffd699;
}

.order-entry.active {
  outline: 3px solid #f5a018;
}

.order-entry + .order-entry {
  margin-top: 20px;
}

.order-header {
  font-size: 13px;
}

.renter-name {
  font-weight: bold;
  font-size: 20px;
  margin-top: 5px;
}

.article-photos {
  display: flex;
  margin-top: 15px;
}

.remove-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #bbb;
  transition: color 400ms;
  padding: 10px;
}

.order-entry:hover .remove-btn {
  color: #999;
}

.remove-btn:hover {
  color: black !important;
  transition: color 0ms;
}
</style>
