<template lang="pug">
  .agv-login-form
    el-form(ref="form" :model="newCustomer" label-width="85px" label-position="left" :rules="rules"
      @submit.native.prevent="onLogin"
      :status-icon="!isLoginFound"
      :show-message="false"
      :hide-required-asterisk="true")
      el-form-item(label="Почта" prop="login")
        el-input.cc-login-input(v-model="newCustomer.login"
          @input="throttledOnLoginChange"
          placeholder="flower-power@mail.ru"
          autocomplete="email"
          type="email"
          name="email")
          i.el-icon-success.el-input__icon.icon-check-circle(slot="suffix" v-if="isLoginFound")
      el-button.login-btn.cc-login-btn(v-if="showLoginButton" type="success" @click="onLogin")
        | Войти
      .signup-form(v-if="showSignupForm")
        el-form-item(label="Имя" prop="first_name")
          el-input.cc-first-name-input(v-model="newCustomer.first_name" placeholder="Анна" name="name" autocomplete="given-name")
        el-form-item(label="Фамилия" prop="last_name")
          el-input.cc-last-name-input(v-model="newCustomer.last_name" placeholder="Кузнецова" autocomplete="family-name")
        el-form-item(label="Телефон" prop="phone")
          el-input.cc-phone-input(v-model="newCustomer.phone" placeholder="89012345678" type="text" autocomplete="tel")
        el-form-item(label="Город")
          el-select.cc-town-select(v-model="newCustomer.town_id" placeholder="Выбрать" filterable style="width: 100%;")
            el-option(:label="town.name" :value="town.id" v-for="town in towns" :key="town.id")
        el-checkbox.cc-is-professional-checkbox(v-model="newCustomer.is_professional" style="margin-bottom: 20px; font-weight: normal;")
          | Я — специалист праздничной сферы
        el-form-item(label="Компания" v-if="newCustomer.is_professional" prop="company_name")
          el-input.cc-company-name-input(v-model="newCustomer.company_name")
        div(v-if="newCustomer.is_professional")
          el-checkbox.cc-is-subscribed-to-newsletter-checkbox(v-model="newCustomer.is_subscribed_to_newsletter" style="margin-bottom: 20px;")
            | Получать новости Пифакита
          .newsletter-description
            | Пишем редко и по делу
        el-button.signup-btn.cc-signup-btn(type="success" @click="onSignup")
          | Зарегистрироваться

</template>

<script>
import Vue from 'vue'

import axios from 'axios'
import {reachGoal} from 'Shared/lib/metrika'
import eventBus from 'Shared/eventBus'
import {EMAIL_REGEX} from 'Aggregator/shared/validation'

export default {
  name: "agv-login-form",
  data() {
    return {
      triedLogin: false,
      isLoginFound: false,
      isLoginLinkSent: false,
      newCustomer: {
        login: this.$store.state.savedLogin || '',
        town_id: parseInt(this.$store.state.route.params.town_id),
        is_subscribed_to_newsletter: true
      },
      rules: {
        login: [
          {required: true},
          {type: "email", trigger: 'blur'}
        ],
        first_name: [
          {required: true}
        ],
        last_name: [
          {required: true}
        ],
        phone: [
          {required: true},
          {type: "string", pattern: /^(8|\+7)?9\d{9}$/, trigger: 'blur'}
        ],
        company_name: [
          {required: true}
        ]
      }
    }
  },
  computed: {
    showLoginButton() {
      return this.triedLogin && this.isLoginFound
    },
    showSignupForm() {
      return this.triedLogin && !this.isLoginFound
    },
    throttledOnLoginChange: function () {
      return this.onLoginChange
    },
    towns() {
      return this.$store.state.town.towns
    }
  },
  methods: {
    onLogin() {
      reachGoal('sendLoginEmail:begin')
      axios.post(
          `/api/aggregator_customer/login`, {login: this.newCustomer.login.toLowerCase()}
      ).then(() => {
        Vue.$localStorage.set('login_back_url', this.$route.path)
        eventBus.$emit('openLoginLinkSentModal', this.newCustomer.login)
        reachGoal('sendLoginEmail:done')
      })
    },
    onSignup() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          reachGoal('signup:begin')
          axios.post(
              `/api/aggregator_customer/signup`,
              _.merge(this.newCustomer, {
                is_subscribed_to_newsletter: this.newCustomer.is_subscribed_to_newsletter && this.newCustomer.is_professional
              })
          ).then(() => {
            eventBus.$emit('openLoginLinkSentModal', this.newCustomer.login)
            reachGoal('signup:done')
          })
        } else {
          return false
        }
      })
    },
    onLoginChange() {
      if (!this.newCustomer.login.match(EMAIL_REGEX)) {
        this.isLoginFound = false
        return
      }

      axios.get(`/api/aggregator_customer/can_login_with?login=${this.newCustomer.login}`)
          .then(({data: result}) => {
            this.triedLogin = true
            this.isLoginFound = result

          }, () => {
            this.triedLogin = true
          })
    }
  },
  created() {
    this.onLoginChange()
  }
}
</script>

<style scoped>
.icon-check-circle {
  color: #67C23A;
  top: 1px;
  position: relative;
}

.login-btn, .signup-btn {
  width: 100%;
}

.signup-btn {
  margin-top: 20px;
}

.el-input >>> .el-input__prefix {
  left: 17px;
}

.newsletter-description {
  margin-left: 24px;
  margin-top: -15px;
  margin-bottom: 20px;
  font-size: 13px;
}

.agv-login-form >>> .el-checkbox__label {
  font-weight: normal;
}
</style>
