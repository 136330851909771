import _ from 'lodash'

function filterAvailableKinds(kinds, availableKindIds) {
  if (!availableKindIds.length) {
    return []
  }
  return _.filter(kinds, k => _.includes(availableKindIds, k.id))
}

export function navSheetSubsections(townId, kinds, availableKindIds) {
  if (!availableKindIds) {
    return []
  }

  return _.map(
    _.orderBy(filterAvailableKinds(kinds, availableKindIds), 'position'),
    (k) => {
      return {
        name: k.name,
        isImportant: true,
        url: `/${townId}/category/${k.aggregator_category_id}/${k.id}`
      }
    }
  )
}


export function navSheetSections(townId, kinds, categories, availableKindIds) {
  if (!(categories && availableKindIds)) {
    return []
  }

  return _.map(
    _.orderBy(categories, 'position'),
    (c) => {
      let items = _.map(
        _.filter(
          filterAvailableKinds(kinds, availableKindIds),
          (k) => {
            return k.aggregator_category_id === c.id
          }),
        (k) => {
          return {
            name: k.name,
            isImportant: !!k.position,
            position: k.position,
            url: `/${townId}/category/${k.aggregator_category_id}/${k.id}`
          }
        }
      )
      return {
        name: c.name,
        url: `/${townId}/category/${c.id}`,
        importantItems: _.filter(items, i => i.isImportant),
        nonImportantItems: _.filter(items, i => !i.isImportant)
      }
    })
}

export function urlFor(kind, townId) {
  return `/${townId}/category/${kind.aggregator_category_id}/${kind.id}`
}
