<template lang="pug">
  .content
    .banners-container
      agv-ad-banner(:limit="12" :slider-mode="true" :show-only-new="true")
    .title
      | Маркетплейс свадебного декора в аренду
    .catalog
      agv-kind-list(:kinds="kindsForList" :town-id="town_id")
    .title(v-if="myRenters.length")
      | Мои прокатчики
    .renter-cards(v-if="myRenters.length")
      router-link.renter-card(v-for="renter in limitedMyRenters" :key="renter.id" :to="`/${town_id}/renter/${renter.id}`")
        .renter-card__title
          | {{renter.short_commercial_name | capitalize}}
        agv-rating.renter-rating(:rating="renter.avg_rating" :rating-count="renter.rating_count")
        .renter-joint-orders-count
          | {{renter.joint_orders_count | decline(['заказ', 'заказа', 'заказов'])}}
          | вместе
    .show-more-renters-btn-container(v-if="myRenters && (limitedMyRenters.length !== myRenters.length)" style="text-align: left; margin-top: 35px; margin-bottom: 20px;")
      .show-more-renters-btn(@click="myRentersLimitedAt6 = false")
        | Показать еще {{(myRenters.length - limitedMyRenters.length)}}
    .title(v-if="favoriteArticles.length")
      | Уже заказывали
    .catalog(v-if="favoriteArticles.length")
      agv-article(v-for="article in favoriteArticles"
        :key="article.id + ':' + article.renter_id"
        :article="article"
        :is-in-wide-view="true"
        :renter="getRenter(article.renter_id)"
        :show-discount="showDiscount"
        :show-rating="true"
        :show-like-button="true"
        :town-id="article.town_id")
    .view-all-btn-container(v-if="favoriteArticles.length" style="text-align: center; margin-top: 20px; margin-bottom: 20px;")
      router-link.show-all-new-btn(:to="`/${town_id}/favorites`")
        | Посмотреть все →
    .title
      | Новинки
    .catalog
      agv-article(v-for="article in newArticles"
        :key="article.id + ':' + article.renter_id"
        :article="article"
        :show-discount="showDiscount"
        :town-id="article.town_id"
        is-in-wide-view
        :renter="getRenter(article.renter_id)"
        :show-rating="true"
        :show-like-button="true"
        :show-created-at="true")
    .view-all-btn-container(style="text-align: center; margin-top: 20px; margin-bottom: 20px;")
      router-link.show-all-new-btn(:to="`/${town_id}/new`")
        | Посмотреть все →
    .testimonials
      .testimonial(style="background: #F7B500")
        img(src="/public/img/aggregator/karina.jpg")
        .testimonial-text-container
          .testimonial-text
            | Самое лучшее в пифаките — что&nbsp;я&nbsp;подбираю весь декор в одном месте.
          .testimonial-author
            | Карина Илиопуло, хозяйка Tandem
      .testimonial(style="background: #0FC83F")
        .testimonial-text-container
          .testimonial-text(style="font-size: 38px;")
            | Пифакит помогает мне отличаться от конкурентов. Все катают сотню предметов, а&nbsp;я&nbsp;—&nbsp;8000🙂
          .testimonial-author
            | Юлия Лакстигаль, хозяйка «Пчёлок»
        img(src="/public/img/aggregator/julia.jpg")
      .testimonial(style="background: #F74D00")
        img(src="/public/img/aggregator/anna.jpg")
        .testimonial-text-container
          .testimonial-text
            | Я верю, что разнообразие развивает рынок. Поэтому пользуюсь&nbsp;пифакитом.
          .testimonial-author
            | Анна Глазатова, дизайнер «Корицы»
      .button-container(style="background: #32C5FF; text-align: center;")
        .testimonial-text
          | Сделайте своё сегодняшнее оформление
          br
          | непохожим на вчерашнее
        .catalog-btn-container
          router-link.catalog-btn(:to="$store.getters.firstKindPath")
            | Перейти в каталог
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

import AgvArticle from 'Aggregator/components/agv-article'
import AgvKindList from 'Aggregator/components/agv-kind-list'

import toAggregatorArticles from 'Aggregator/shared/toAggregatorArticles'

import store from 'Aggregator/store/index'

import imageMapResize from 'image-map-resizer'
import AgvArticlesList from "Aggregator/components/agv-articles-list.vue";
import AgvAdBanner from "Aggregator/components/agv-ad-banner.vue";
import AgvRating from "Aggregator/components/agv-rating.vue";

let townsWithHomePageIds = _.compact(
    _.map(
        store.state.PRIVILEGED_TOWNS,
        townName => _.get(_.find(store.state.town.towns, t => t.name === townName), 'id')
    )
)

let maybeRedirectToChairs = (to, next) => {
  if (_.includes(townsWithHomePageIds, parseInt(to.params.town_id))) {
    next()
  } else {
    next({path: `/${to.params.town_id}/all`})
  }
}

export default {
  name: 'page-agv-home',
  data() {
    return {
      renter_id: null,
      newArticles: [],
      myRenters: [],
      favoriteArticles: [],
      myRentersLimitedAt6: true
    }
  },
  beforeRouteEnter(to, from, next) {
    maybeRedirectToChairs(to, next)
  },
  beforeRouteUpdate(to, from, next) {
    maybeRedirectToChairs(to, next)
  },
  computed: {
    limitedMyRenters() {
      if (this.myRentersLimitedAt6) {
        return _.take(this.myRenters, 6)
      }
      return this.myRenters
    },
    town_id() {
      return this.$route.params.town_id
    },
    town() {
      return this.$store.getters.town
    },
    kinds() {
      return this.$store.state.kinds
    },
    categories() {
      return this.$store.state.kinds
    },
    kindsForList() {
      const counts = this.$store.state.kindCountsByIdByTownId[this.town_id]

      if (!counts) {
        return []
      }

      return _.take(
          _.compact(
              _.map(
                  this.kinds,
                  k => {
                    return {
                      id: k.id,
                      name: k.name,
                      count: counts[k.id],
                      url: `/${this.town_id}/category/${k.aggregator_category_id}/${k.id}`,
                      image_url: k.image_url
                    }
                  }
              )
          ),
          25
      )
    },
    availableKindIds() {
      return store.state.availableKindIdsByTownId[this.town_id]
    },
    frontPageBanners() {
      return _.orderBy(
          _.filter(this.$store.state.banners, b => !b.town_id || b.town_id === this.town_id),
          'position'
      )
    },
    firstBanner() {
      return _.first(this.frontPageBanners)
    },
    showDiscount() {
      return this.$store.getters.showDiscount
    },
  },
  methods: {
    getRenter(id) {
      return this.$store.state.rentersById[id]
    },
  },
  components: {AgvAdBanner, AgvArticlesList, AgvArticle, AgvKindList, AgvRating},
  created() {
    setTimeout(() => {
      imageMapResize()
    }, 1000)

    axios.get(`/api/renters/${this.town_id}/my_renter_ids`)
        .then(({data: renterIds}) => {
          this.myRenters = _.compact(
              _.map(renterIds, ({id, total_order_count}) => {
                return {
                  ...this.$store.state.rentersById[id],
                  joint_orders_count: total_order_count
                }
              }))
        }).finally(() => {
      axios.get(`/api/aggregator_customer/favorite_articles/${this.town_id}`)
          .then(({data}) => {
            this.favoriteArticles = _.take(data.articles, 8)
          })

      axios.get(`/api/articles/${this.town_id}/new_articles`)
          .then(response => {
                const newArticles = _.take(response.data.articles, 28)
                toAggregatorArticles(newArticles)
                this.newArticles = newArticles
              }
          )
    })
  }
}
</script>

<style scoped>
.content {
  margin-bottom: 0;
}

.content >>> .el-carousel__container {
  height: calc((100vw - 120px) * 0.45);
  min-height: 439px;
}

.front-page-banner {
  width: 100%;
}

.banners-container {
  margin-bottom: 42px;
}

.catalog {
  display: flex;
  flex-wrap: wrap;
}

.catalog .ag-article {
  flex-basis: 25%;
}

.title {
  line-height: 42px;
  font-size: 24px;
  display: block;
  padding-top: 42px;
  margin-bottom: 30px;
}

.nav-sheet {
  height: 660px;
}

.nav-sheet >>> .section {
  width: 33%;
}

.decor-map-links {
  background-image: url('/public/img/aggregator/decor-map-photo.jpg');
  background-size: cover;
}

.decorator-link-container {
  color: white;
  font-size: 16px;
}

.decorator-link {
  color: white;
  text-decoration: underline;
}

area {
  outline: none !important;
}

.testimonials {
  margin-top: 70px;
}

.testimonial {
  margin: 0 -60px;
  display: flex;
}

.testimonial-text-container {
  padding: 82px 98px 0;
  color: #FFFFFF;
}

.testimonial-text {
  font-size: 44px;
  line-height: 49px;
  font-weight: 500;
}

.testimonial-author {
  margin-top: 33px;
  font-size: 18px;
}

.button-container {
  margin: 0 -60px;
  padding: 105px 98px 110px;
  color: white;
}

.catalog-btn-container {
  display: block;
  text-align: center;
  margin-top: 50px;
}

.catalog-btn {
  background: #B620E0;
  border-radius: 4px;
  padding: 10px 23px;
  font-size: 26px;
  color: #FFFFFF;
  text-align: center;
}

.show-all-new-btn {
  background: white;
  border-radius: 4px;
  border: 1px solid #999;
  padding: 12px 19px;
  font-size: 20px;
  color: #888;
  text-align: center;
  cursor: pointer;
}

.catalog-btn:hover {
  background: #c029f2;
}

.renter-card {
  border: #D2D2D2 1px solid;
  border-radius: 6px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 200px;
  box-sizing: border-box;
  cursor: pointer;
}

.renter-card__title {
  font-size: 20px;
  font-weight: bold;
  color: #2C2C2C;
}

.renter-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.renter-rating {
  font-size: 15px;
  color: #8D8D8D;
}

.renter-rating >>> .star {
  transform: scale(calc(15 / 12));
  top: 0
}

.renter-rating-container {
  display: flex;
  flex-direction: column;
}

.renter-joint-orders-count {
  color: #8D8D8D;
  font-size: 15px;
  margin-top: auto;
}

.show-more-renters-btn {
  color: #8D8D8D;
  font-size: 15px;
  cursor: pointer;
}
</style>
