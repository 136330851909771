<template lang="pug">
  .root(v-hotkey="keymap")
    transition(name="fade")
      .error-report(v-if="showErrorReport")
        | Вы отправили отчет об ошибке. Спасибо!
    vue-progress-bar
    order-item-added-notification(:quantity="lineItemsQuantity" :get-orders-url="getOrdersRouteUrl")
    el-dialog.login-dialog.cc-login-dialog(title="Вход на пифакит" :visible.sync="showLoginModal" width="410px" top="7vh")
      agv-login-form(v-if="showLoginModal" ref="agvLoginForm")
    el-dialog.cc-login-link-sent-dialog(:visible.sync="showLoginLinkSentModal" width="410px" top="7vh")
      agv-login-link-sent(:email="loginLinkSentModalEmail")
    .topmost-section-container
      .topmost-section.wrapper
        span.tagline(v-if="$store.state.visitorsNow")
          .online-icon
            | ●
          |
          | Сейчас на пифаките
          | {{$store.state.visitorsNow | decline(['декоратор', 'декоратора', 'декораторов'])}}
        span.tagline(v-else)
          | Маркетплейс свадебного декора в аренду
        el-select.branch-select(size="mini" :value="townIdForSitewideUrls"
          placeholder="Выберите город"
          filterable
          :default-first-option="true"
          @change="onTownIdChanged")
          el-option-group(v-for="townGroup in townGroups" :key="townGroup.position")
            el-option(v-for="town in townGroup.towns" :key="town.id" :label="town.name" :value="String(town.id)")
        .spreader
        agv-town-info(:town='town' v-bind:branch-info='branchInfo' :is-logged-in-to-dashboard="$store.state.isLoggedInToDashboard")
    .wrapper
      .header
        .aggregator-header.header__inner.clearfix
          router-link.logo-link(:to='`/${town_id}/`')
            .pifakit-logo
              | пифакит
          .search-wrapper
            el-autocomplete(v-model='searchTerms'
              :trigger-on-focus="false"
              :select-when-unmatched="true"
              placeholder="Я хочу взять в аренду..."
              ref="search"
              @select="onSearchSelect"
              :fetch-suggestions="fetchSuggestions")
              template(slot-scope="{ item }")
                .suggestion(@touchstart="goToItem(item)")
                  .suggestion__photo
                    el-image(style="width: 30px; height: 30px" :src="item.image_url" fit="scale-down")
                      .image-slot(slot="placeholder")
                      .image-slot(slot="error")
                  .value
                    | {{item.value}}
                    |
                    span.articles-count
                      | — {{item.articles_count}}
            .search-btn(@click="goToSearch")
              | Найти
          router-link.catalog-link(:to="catalogUrl")
            | Каталог
          .padder
          router-link.account-link(v-if="$store.getters.anyComparedArticles" :to='`/${town_id}/compare`')
            agv-icon-comparison.icon-comparison(:quantity="comparedArticles.length")
            | Сравнение
          router-link#shopping-bag-link.account-link(v-if="firstOrderRenterId" :to='`/${town_id}/renter/${firstOrderRenterId}/order/new`')
            agv-shopping-bag.btn-icon(:quantity="lineItemsQuantity")
            | Корзина
          router-link.account-link(v-if="customer.id" :to="customerLink")
            icon-person.btn-icon
            | Мой кабинет
          el-button.account-link(v-else type="text" @click="proceedToLogin")
            icon-person.btn-icon
            | Войти
      router-view(@proceedToLogin="proceedToLogin")
    back-to-top(text="Наверх" visibleoffset="750")
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import axios from 'axios'

import Vue from 'vue'

import BackToTop from 'vue-backtotop'

import eventBus from 'Shared/eventBus'

import AgvBranchSelect from 'Aggregator/components/agv-branch-select'
import AgvTownInfo from 'Aggregator/components/agv-town-info'
import AgvIconComparison from 'Aggregator/components/agv-icon-comparison'

import IconComparison from 'Aggregator/sprites/icon-comparison.svg'
import IconPerson from 'Aggregator/sprites/icon-person.svg'

import aggregatorRouter from 'Aggregator/aggregatorRouter'

import store from 'Aggregator/store/index'
import AgvLoginForm from 'Aggregator/components/agv-login-form'
import AgvShoppingBag from 'Aggregator/components/agv-shopping-bag'
import OrderItemAddedNotification from 'Shared/components/order-item-added-notification'
import {setupProgressBar} from 'Shared/lib/progressBar'
import {reachGoal} from 'Shared/lib/metrika'
import AgvLoginLinkSent from 'Aggregator/components/agv-login-link-sent'

let categoriesById = _.keyBy(window.categories, 'id')

function trimRussianEndings(word) {
  const wordWithTrimmedWhitespace = word.trim()
  const endings = [
    'ом', 'ов', 'ам', 'ах', 'ами', 'ой', 'ий', 'ый', 'ая', 'ое', 'ее', 'ие', 'ые', 'ья', 'ы', 'а', 'о', 'и', 'е', 'я', 'ь', 'й', 'у', 'ю'
  ];

  for (let ending of endings) {
    if (wordWithTrimmedWhitespace.endsWith(ending)) {
      return wordWithTrimmedWhitespace.slice(0, -ending.length);
    }
  }

  return wordWithTrimmedWhitespace;
}

_.forEach(window.kinds, (k) => {
  k.compound_position = categoriesById[k.aggregator_category_id].position * 100 + (k.position || 10)
})

store.dispatch('customer/load')

export default {
  name: 'app',
  data() {
    const PRIVILEGED_TOWNS = store.state.PRIVILEGED_TOWNS

    _.each(window.towns, (t) => {
      t.isPrivileged = _.includes(PRIVILEGED_TOWNS, t.name)
      t.isBold = t.isPrivileged
      t.label = t.name
    })

    return {
      showPoll: false,
      kinds: window.kinds,
      showErrorReport: false,
      keymap: {
        'alt+g': () => {
          Sentry.captureMessage('User submitted error', {extra: {newOrders: JSON.stringify(localStorage.getItem('aggregator.newOrders'))}})
          this.showErrorReport = true
          setTimeout(() => {
            this.showErrorReport = false
          }, 2000)
        }
      },
      branchInfo: null,
      searchTerms: this.$route.params.searchTerms,
      renter_id: null,
      branches: null,
      category: {},
      towns: window.towns,
      tagsByKindId: window.tagsByKindId,
      showLoginModal: false,
      showLoginLinkSentModal: false,
      loginLinkSentModalEmail: null
    }
  },
  components: {
    AgvLoginLinkSent,
    OrderItemAddedNotification,
    AgvShoppingBag,
    AgvLoginForm,
    AgvIconComparison,
    BackToTop,
    AgvBranchSelect,
    AgvTownInfo,
    IconComparison,
    IconPerson
  },
  router: aggregatorRouter,
  methods: {
    getOrdersRouteUrl(renterId) {
      return `/${this.$route.params.town_id}/renter/${renterId}/order`
    },
    onTownIdChanged(id) {
      Vue.cookie.set('town_id', id)
      this.$router.replace({params: {town_id: id}})
      this.$store.commit('order/clearAllNewOrders')
      this.fetchBranchInfo()
    },
    proceedToLogin() {
      this.showLoginModal = true
      reachGoal('proceedToLogin')
    },
    fetchSuggestions(queryString, callback) {
      if (!queryString || queryString.length < 3) {
        return callback([])
      }

      axios.get(`/api/search_suggestions?query=${queryString}&town_id=${this.town_id}`).then(
          ({data: suggestions}) => {
            callback(suggestions)
          })
    },
    onSearchSelect(item) {
      if (item.url_part_after_town) {
        this.$router.push(`/${this.town_id}/${item.url_part_after_town}`)
        return
      }

      this.goToSearch()
    },
    goToSearch() {
      if (!this.searchTerms) {
        return
      }

      const searchTermsWithTrimmedEnding = trimRussianEndings(this.searchTerms)

      const kind = _.find(this.kinds, k => {
        return trimRussianEndings(k.name).toLowerCase() === searchTermsWithTrimmedEnding.toLowerCase()
      })

      if (kind) {
        this.$router.push(`/${this.town_id}/category/${kind.aggregator_category_id}/${kind.id}`).catch(err => {
        })
        return
      }

      this.$router.push(`/${this.town_id}/search/${this.searchTerms}`).catch(err => {
      })
    },
    goToItem(item) {
      this.$refs.search.$refs.input.clear()
      this.$router.push(`/${this.town_id}/${item.url_part_after_town}`)
    },
    fetchBranchInfo() {
      if (!this.town_id) {
        return
      }

      axios.get(`/api/branches/${this.town_id}/info`)
          .then(({data: branch}) => {
            // this.kinds = _.flatten(_.map(this.categories, c => c.aggregator_kinds))
            this.branchInfo = branch
            store.dispatch('fetchAvailableKindIds', this.town_id)
          })
    },
  },
  watch: {
    $route(to) {
      if (!to.params.searchTerms) {
        this.searchTerms = ''
      }
    },
    town_id: {
      handler: 'fetchBranchInfo',
      immediate: true
    }
  },
  computed: {
    townIdForSitewideUrls() {
      return this.town_id || Vue.cookie.get('town_id')
    },
    townGroups() {
      const towns = this.$store.state.town.towns
      const mainTowns = _.orderBy(
          _.filter(towns, t => {
                return _.includes(this.$store.state.PRIVILEGED_TOWNS, t.name)
              }
          ),
          ['population'],
          ['desc']
      )
      const otherTowns = _.difference(towns, mainTowns)
      return [
        {
          towns: mainTowns
        },
        {
          towns: otherTowns
        }
      ]
    },
    town_id() {
      return this.$route.params.town_id
    },
    town() {
      return this.$store.getters.town
    },
    catalogUrl() {
      return this.$store.getters.firstKindPath
    },
    firstOrderRenterId() {
      return _.first(_.keys(store.state.order.ordersByRenterId))
    },
    lineItemsQuantity() {
      return store.getters['order/lineItemsQuantity']
    },
    customer() {
      return store.state.customer.current
    },
    customerLink() {
      if (_.get(this.customer, 'has_orders')) {
        return '/customer/orders'
      }
      return '/customer/details'
    },
    availableKindIds() {
      return store.state.availableKindIdsByTownId[this.town_id]
    },
    metaInfo() {
      return {
        titleTemplate: `%s | Пифакит`,
      }
    },
    comparedArticles() {
      return _.flatten(_.values(this.$store.state.comparedArticleIdsByRenter))
    },
    memberAddress() {
      return window.memberAddress
    },
    townsWithoutSelected() {
      return _.without(this.towns, this.town)
    },
  },
  created() {
    eventBus.$on('proceedToLogin', () => {
      this.showLoginLinkSentModal = false
      this.showLoginModal = true
    })

    eventBus.$on('openLoginLinkSentModal', (email) => {
      this.showLoginLinkSentModal = true
      this.loginLinkSentModalEmail = email
      this.showLoginModal = false
    })

    eventBus.$on('order:cleared', () => {
      if (this.$route.path.match(/renter\/\d+\/order\/new/)) {
        const mostRecentNewOrderRenterId = _.get(
            _.last(
                _.orderBy(
                    _.map(this.$store.state.order.ordersByRenterId, (o, renterId) => {
                      return {
                        added_at: o.added_at,
                        renter_id: renterId
                      }
                    }),
                    'added_at'
                )
            ),
            'renter_id'
        )
        const newPath = mostRecentNewOrderRenterId ?
            `/${this.town_id}/renter/${mostRecentNewOrderRenterId}/order/new/`
            :
            `/${this.town_id}/`

        setTimeout(() => {
          this.$router.push(newPath)
        }, 0)
      }
    })

    setupProgressBar(this.$Progress, axios)

    const backUrl = Vue.$localStorage.get('login_back_url')
    if (window.shouldGoToBackUrl === 'true' && backUrl) {
      this.$router.push(backUrl)
    }

    setTimeout(
        function () {
          $('.pifakit-logo, .catalog-link, .account-link').click(
              function () {
                const el = $(this)
                el.addClass('pressed')
                setTimeout(() => {
                  el.removeClass('pressed')
                }, 280)
              })
        }, 0)
  }
}
</script>

<style src='element-ui/lib/theme-chalk/index.css'>
/* global styles */
</style>

<!--suppress CssUnusedSymbol -->
<style scoped>
/*.root >>> .el-dialog {*/
/*background: #F0F2F3;*/
/*margin-top: 5vh !important;*/
/*border-radius: 8px;*/
/*}*/

.online-icon {
  display: inline-block;
  color: #63cc45;
  margin-right: 2px;
  animation: blinkingIcon 30s infinite;
}

@keyframes blinkingIcon {
  0% {
    color: #63cc45;
  }
  1% {
    color: transparent;
  }
  2% {
    color: #63cc45;
  }
  100% {
    color: #63cc45;
  }
}

.root >>> .el-button {
  font-weight: normal;
}

.root >>> .el-button--text {
  color: #0074d9;
}

.root >>> .el-button--text:hover {
  color: #008cff;
}

.root >>> .el-dialog__body {
  /*padding: 0 0 3px;*/
  word-break: keep-all !important;
}

.root >>> .nav-sheet-full .section {
  width: 320px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.appear-enter-active, .appear-leave-active {
  transition: bottom .5s;
}

.appear-enter, .appear-leave-to {
  bottom: -70px;
}
</style>

<style scoped>
iframe {
  border: none;
}

.error-report {
  position: absolute;
  width: 200px;
  padding: 10px 20px;
  background: #b3ddff;
  opacity: 0.9;
  top: 20px;
  right: 20px;
  border-radius: 3px;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  z-index: 100;
}

.aggregator-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.header__inner {
  padding: 20px 0;
  margin-bottom: 28px;
}

.header__inner >>> .el-button {
  padding: 0;
}

.padder {
  width: 9px;
  flex-basis: 9px;
  flex-grow: 0.5;
}

.logo-link {
  margin-right: 30px;
  align-self: center;
  padding-bottom: 5px;
}

.pifakit-logo {
  font-size: 38px;
  color: #0a0a0a;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  transition: color 100ms;
}

.pifakit-logo:hover {
  color: #555;
}

.pifakit-logo.pressed {
  color: #888 !important;
}

.account-link:hover, .account-link.el-button--text:hover {
  color: #555;
}

.account-link.pressed {
  color: #888 !important;
}

.search-wrapper {
  display: flex;
  flex-grow: 1;
  flex-basis: 223px;
}

.branch-select {
  margin-left: 16px;
  margin-right: 10px;
  align-self: center;
}

.wrapper {
  width: 100%;
  padding: 0 60px;
  max-width: 1400px;
  min-width: 1100px;
  margin: 0 auto;
}

.search-btn {
  flex-basis: 50px;
  line-height: 28px;
  font-size: 14px;
  height: 50px;
  color: white;
  background: #9B9B9B;
  border: 1px solid #9B9B9B;
  border-left: none;
  cursor: pointer;
  border-radius: 0 4px 4px 0;

  display: flex;
  align-items: center;

  padding: 0 18px;
}

.search-btn:hover {
  background: #b3b3b3;
}

.account-link, .account-link.el-button--text {
  font-size: 17px;
  color: black;
  text-decoration: none !important;
  transition: color 100ms;
}

.account-link + .account-link {
  margin-left: 18px;
}

.topmost-section {
  height: 45px;
  display: flex;
  align-items: center;
}

@media (pointer: coarse) {
  .topmost-section {
    border-bottom: 1px solid #E7E7E7;
  }
}

@media (pointer: fine) {
  .topmost-section-container {
    border-bottom: 1px solid #E7E7E7;
  }
}

.tagline {
  font-size: 12px;
}

.link {
  text-decoration: underline;
  font-size: 14px;
  color: #686868;
  margin-left: 20px;
}

.spreader {
  flex-grow: 1;
}

.plus-icon {
  margin-right: 10px;
  position: relative;
  top: 7px;
}

.catalog-link {
  margin-left: 20px;

  display: flex;
  align-items: center;

  line-height: 28px;
  font-size: 14px;
  height: 50px;
  color: white;
  background: #4990E2;
  border-left: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 24px;
  user-select: none;
  position: relative;
  transition: color 200ms;
}

.catalog-link:hover {
  background: #54a7ff;
}

.catalog-link.pressed {
  background: #4385cc !important;
}

.btn-to-top {
  width: 60px;
  height: 60px;
  padding: 10px 16px;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
}

.icon-comparison {
  position: relative;
  top: 9px;
  margin-right: 8px;
}

.login-dialog >>> .el-dialog__header {
  padding-top: 32px;
  padding-bottom: 2px;
}

.login-dialog >>> .el-dialog__title {
  font-size: 22px;
}

.suggestion {
  display: flex;
  align-items: center;
  height: 40px;
}

.suggestion__photo {
  margin-right: 10px;
  display: flex;
}

.articles-count {
  color: #a8a8a8;
}

.el-autocomplete {
  width: 100%;
}

.el-autocomplete >>> input {
  height: 50px;
  border-color: #bbb;
}

.el-autocomplete >>> .el-input__inner {
  height: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.account-link {
  font-size: 17px;
}

.btn-icon {
  margin-right: 9px;
  position: relative;
  top: 6px;
}
</style>

<style src='../shared/styles/back-to-top.css' lang="css">
/* back to top */
</style>

<style src='../shared/styles/reset.css' lang="css">
/* global styles */
</style>

<style src='../shared/styles/common.css' lang="css">
/* global styles */
</style>

<style src='../shared/styles/page.css' lang="css">
/* global styles */
</style>
