<template lang="pug">
  .agv-renter-info
    .renter-info-title
      | О прокатчике
    table.renter-info-table
      tr.row.renter-name-row
        td.label
          router-link.renter-name(:to="`/${townId}/renter/${renter.id}`")
            | {{renter.short_commercial_name}}
        td.value
          agv-rating(:rating="renter.avg_rating" :rating-count="renter.rating_count")
          .renter-school-graduate(v-if="renter.is_renter_school_graduate")
            icon-renter-school-medal.icon-renter-school-medal
            .graduate
              | Выпускник
              br
              a(href="https://rent-school.ru/launch" target="_blank" style="color: black; border-bottom: 1px solid lightgray;")
                | Школы прокатчиков
          br
          agv-report-renter(:renter="renter")
      tr.row(v-if="renter.last_order_created_at")
        td.label
          | Последний заказ
        td.value
          | {{lastOrderCreatedAt}}
      tr.row(v-if="goodPunctualityPercentage && !abDisableExtRatings")
        td.label
          | Пунктуальность
        td.value
          .violations-percentage
            | {{formatPercentage(goodPunctualityPercentage)}}%
          |
          | заказов без опозданий
          .violations-out-of
            | на основании {{renter.punctuality_violations.out_of | decline(['заказа', 'заказов', 'заказов'])}}
      tr.row(v-if="goodConditionPercentage && !abDisableExtRatings")
        td.label
          | Состояние
          br
          | изделий
        td.value
          .violations-percentage
            | {{formatPercentage(goodConditionPercentage)}}%
          |
          | заказов без поврежденных изделий
          .violations-out-of
            | на основании {{renter.condition_violations.out_of | decline(['заказа', 'заказов', 'заказов'])}}
      tr.row(v-if="goodCleanlinessPercentage && !abDisableExtRatings")
        td.label
          | Чистота
          br
          | изделий
        td.value
          .violations-percentage
            | {{formatPercentage(goodCleanlinessPercentage)}}%
          |
          | заказов без грязных изделий
          .violations-out-of
            | на основании {{renter.cleanliness_violations.out_of | decline(['заказа', 'заказов', 'заказов'])}}
      tr.row
        td.label
          | Пункт выдачи
        td.value
          agv-ri-cp(:branch="branch")
      tr.row
        td.label
          | Профскидка
        td.value
          agv-ri-discount(:renter="renter")
      tr.row
        td.label
          | Минимальный заказ
        td.value
          span(v-if="renter.smallest_rent_price_with_delivery === 0 && renter.smallest_rent_price_without_delivery === 0")
            | Нет
          span(v-else-if="renter.smallest_rent_price_with_delivery === renter.smallest_rent_price_without_delivery")
            | {{renter.smallest_rent_price_with_delivery | money(renter.country)}}
          span(v-else)
            | {{renter.smallest_rent_price_without_delivery | money(renter.country)}} — при самовывозе
            br
            | {{renter.smallest_rent_price_with_delivery | money(renter.country)}} — при доставке
      tr.row
        td.label
          | Доставка
        td.value
          agv-ri-delivery(:renter="renter")
      tr.row
        td.label
          | Оплата
        td.value
          agv-ri-payment-methods(:renter="renter")
      tr.row
        td.label
          | Залог
        td.value
          span(v-if="renter.deposit_requirement === 2")
            | Берется со всех клиентов,
            br
            | расчет&nbsp;индивидуально
          span(v-else-if="renter.deposit_requirement === 1")
            | Берется с частных клиентов,
            br
            | расчет&nbsp;индивидуально
          span(v-else)
            | Не берется
      tr.row
        td.label
          | Телефон
        td.value
          agv-ri-contacts(:renter="renter" :branch="branch")
      tr.row
        td.label
        td.value
          a(:href="`/${townId}/renter/${renter.id}/rules`" target="_blank")
            | Условия работы
</template>

<script>
  import numeral from 'numeral'
  import moment from 'moment'

  import AgvRating from 'Aggregator/components/agv-rating'
  import AgvRiDiscount from 'Aggregator/components/renter-info/agv-ri-discount'
  import AgvRiDelivery from 'Aggregator/components/renter-info/agv-ri-delivery'
  import AgvRiPaymentMethods from 'Aggregator/components/renter-info/agv-ri-payment-methods'
  import AgvRiContacts from 'Aggregator/components/renter-info/agv-ri-contacts'
  import AgvRiCp from 'Aggregator/components/renter-info/agv-ri-cp'

  import IconRenterSchoolMedal from '-!vue-svg-loader!../sprites/icon-renter-school-medal.svg'
  import AgvReportRenter from 'Aggregator/components/agv-report-renter'

  function getGoodOccurencesPercentage(renter, attribute) {
    const {count, out_of} = renter[`${attribute}_violations`]

    if (out_of < 10) {
      return
    }

    const percentage = Math.round((1 - count / out_of) * 100 * 10) / 10

    if (percentage < 98) {
      return Math.round(percentage)
    }

    return percentage
  }

  export default {
    name: 'agv-renter-info',
    components: {
      AgvReportRenter,
      AgvRiCp,
      AgvRiContacts,
      AgvRiPaymentMethods,
      AgvRiDelivery,
      AgvRiDiscount,
      AgvRating,
      IconRenterSchoolMedal
    },
    props: {
      renter: Object,
      branch: Object,
      townId: String,
      abDisableExtRatings: Boolean
    },
    methods: {
      formatPercentage(rating) {
        return numeral(rating).format('0.[0]')
      }
    },
    computed: {
      goodPunctualityPercentage() {
        return getGoodOccurencesPercentage(this.renter, 'punctuality')
      },
      goodConditionPercentage() {
        return getGoodOccurencesPercentage(this.renter, 'condition')
      },
      goodCleanlinessPercentage() {
        return getGoodOccurencesPercentage(this.renter, 'cleanliness')
      },
      lastOrderCreatedAt() {
        if (!this.renter.last_order_created_at) {
          return null
        }
        return moment.utc(this.renter.last_order_created_at).startOf('minute').fromNow()
      }
    }
  }
</script>

<style scoped>
  .agv-renter-info {
    width: 330px;
    min-width: 330px;
    margin-left: 53px;
  }

  .renter-info-title {
    font-weight: bold;
    font-size: 18px;
    color: #4A4A4A;
    margin-bottom: 28px;

  }

  .renter-info-table td {
    padding: 0;
  }

  .renter-name {
    font-size: 15px;
    margin-right: 5px;
  }

  .agv-renter-info >>> .agv-rating div {
    display: inline-block;
  }

  .row td {
    padding-bottom: 25px;
  }

  .renter-name-row td {
    vertical-align: baseline;
  }

  .label {
    width: 144px;
    min-width: 144px;
    padding-right: 10px;
  }

  .cp-address {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 2px;
  }

  .deposit-info {
    margin-top: 5px;
  }

  .violations-percentage {
    font-weight: bold;
    display: inline-block;
  }

  .violations-out-of {
    color: grey;
  }

  .renter-school-graduate {
    font-size: 11px;
    margin-top: 10px;
    display: flex;
  }

  .icon-renter-school-medal {
    margin-right: 8px;
    position: relative;
  }
</style>
