/*eslint no-var: "error"*/
/*eslint-env es6*/
//@flow

import Vue from 'vue'

import axios from 'axios'
import qs from 'querystring'
import _ from 'lodash'

import store from 'Aggregator/store/index'
import type {PaymentMethodString} from 'Shared/payment'
import {getPaymentSum} from 'Shared/payment'

export function findOrder(orderId: number, renterId: number) {
  return axios.get(`/api/renters/${renterId}/orders/${orderId}.json`)
}

export function getExportUrl(orderId, renterId) {
  return `/api/renters/${renterId}/orders/${orderId}.pdf`
}

export function getArticle(id, renterId) {
  return store.getters['articles/getByRenterIdAndArticleId'](renterId, id)
}

export function getArticleUrl(articleId, renterId) {
  return `/${store.getters.townId}/renter/${renterId}/article/${articleId}`
}

export function getOrdersUrl(renterId) {
  return `/api/renters/${renterId}/orders`
}

export function getOrderBillUrl(renterId: number,
                                orderId: number,
                                paymentMethod: PaymentMethodString,
                                sum: number,
                                balance: number,
                                bankDetails: ?{ legal_name: string, address: string, tin: string, rrc: string }
) {
  return `/api/renters/${renterId}/orders/${orderId}/customer_bills/create/${paymentMethod}/` +
    getPaymentSum(sum, balance) + (bankDetails ? '?' + qs.stringify(
        _.pick(bankDetails, ['legal_name', 'address', 'tin', 'rrc']) || {}
      ) : ''
    )
}

export function getLastWireTransferBillDetailsUrl(renterId: number) {
  return `/api/renters/${renterId}/customer_bills/last_wire_transfer_bill/`
}

export function getCustomerBillsUrl(renterId) {
  return `/api/renters/${renterId}/customer_bills`
}

export function getOrdersRouteUrl(renterId, townId) {
  return `/${townId}/renter/${renterId}/order`
}

export function getStateUrl(renterId) {
  return `/api/renters/${renterId}/state`
}

export function getIsOrderSelectable(order, selectedOrders) {
  return false
  const firstSelectedOrder = selectedOrders[0]
  const isOfRequiredState = order.state === 'payment_pending' || order.state === 'paid'
  const canBeDeliveredByAggregator = !order.is_delivery_mandatory
  const canBeSelected = isOfRequiredState && canBeDeliveredByAggregator

  if (!selectedOrders.length) {
    return canBeSelected
  }
  return canBeSelected &&
    order.acquisition === firstSelectedOrder.acquisition &&
    order.returning === firstSelectedOrder.returning
}
