<template lang="pug">
  .agv-customer-orders-page-content.form-container(v-if="orders")
    .page-heading
      h1.page-title.page-title_top
        | {{title}}
      .span(v-if="areCurrentOrdersPresent && currentOrders.length !== orders.length")
        .expand-btn.link-btn(v-if='showAll' @click="showAll = false")
          | Показать текущие
        .expand-btn.link-btn(v-else @click="showAll = true")
          | Показать все заказы
    .merge-buttons(v-if="false && hasMoscowOrders")
      div(v-if="isSelectingOrders")
        el-button.cc-btn-cancel-consolidation(@click="isSelectingOrders = false" type="info")
          | Отменить
        el-button.cc-btn-consolidate(@click="onConsolidateOrders" type="success" :disabled="!ordersSelectedForConsolidation.length")
          | Объединить
      el-button.cc-delivery-btn(v-else @click="isSelectingOrders = true")
        icon-merge.icon-merge
        | Объединить заказы
    div(v-if="orders && orders.length === 0")
      | Пока у вас нет заказов
    agv-customer-orders-table(v-else v-loading="!orders"
      country-code="ru"
      :show-renter="true"
      :is-prolongation-disabled="false"
      :is-selecting-orders="isSelectingOrders"
      :get-orders-route-url="getOrdersRouteUrl"
      :get-orders-url="getOrdersUrl"
      :get-is-order-selectable="getIsOrderSelectable"
      :get-delivery-path="getDeliveryPath"
      :orders="shownOrders")
</template>

<script>
  import axios from 'axios'
  import dayjs from 'dayjs'
  import _ from 'lodash'

  import {getOrdersRouteUrl, getOrdersUrl, getIsOrderSelectable} from 'Aggregator/lib/order'
  import AgvCustomerOrdersTable from 'Aggregator/agv-customer-orders-table'

  import IconMerge from 'Aggregator/sprites/merge.svg'

  export default {
    name: 'page-agv-customer-orders-list',
    components: {AgvCustomerOrdersTable, IconMerge},
    props: {
      customer: Object,
      town_id: String
    },
    data() {
      return {
        orders: null,
        isSelectingOrders: false,
        showAll: false,
        getOrdersRouteUrl,
        getOrdersUrl,
        moscow: _.find(this.$store.state.town.towns, t => t.name === 'Москва')
      }
    },
    computed: {
      hasMoscowOrders() {
        if (!this.moscow) {
          return false
        }
        return !!_.find(this.orders, o => o.town_id === this.moscow.id)
      },
      title() {
        return this.shownOrdersSubset === 'current' ? 'Текущие заказы' : 'Мои заказы'
      },
      areCurrentOrdersPresent() {
        return this.currentOrders.length > 0
      },
      currentOrders() {
        return _.filter(this.orders, o => {
          const now = dayjs()
          const returning = dayjs(o.returning)
          return o.state_code < 5 ||
            o.state_code === 6 && returning.diff(now, 'day') >= 0
        })
      },
      shownOrdersSubset() {
        if (!this.areCurrentOrdersPresent) {
          return 'all'
        }

        if (this.showAll) {
          return 'all'
        } else {
          return 'current'
        }
      },
      shownOrders() {
        return this.shownOrdersSubset === 'current' ? this.currentOrders : this.orders
      },
      ordersSelectedForConsolidation() {
        return _.filter(this.orders, 'is_selected')
      }
    },
    methods: {
      getDeliveryPath(id) {
        return `/customer/consolidated-delivery/${id}`
      },
      onConsolidateOrders() {
        this.$router.push(
          {
            name: 'new-consolidated-delivery',
            params: {
              orderIdsByRenterId: _.mapValues(
                _.groupBy(this.ordersSelectedForConsolidation, o => parseInt(o.renter_id)),
                orders => _.map(orders, 'id')
              )
            }
          }
        )
      },
      fetchOrders() {
        axios.get('/api/orders').then(({data: orders}) => {
          _.each(orders, o => { o.paid = o.paid_cached })
          this.orders = orders
        })
      },
      getIsOrderSelectable(order) {
        return !order.aggregator_delivery_id &&
          getIsOrderSelectable(order, this.ordersSelectedForConsolidation)
      }
    },
    created() {
      this.fetchOrders()
    }
  }
</script>

<style scoped>
  .icon-merge {
    width: 20px;
    margin-bottom: -2px;
    margin-right: 5px;
  }

  .page-heading {
    margin-bottom: 20px;
  }
</style>
