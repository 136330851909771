import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

import createOrderStore from 'Shared/store/order/index.es6'
import createArticlesStore from 'Shared/store/articles'
import createCustomerStore from 'Shared/store/customer'
import {getAvailableForPeriodOrTotal} from 'Shared/availabilityUtilites'
import createAvailabilityStore from 'Shared/store/availability/index'
import createRenterCustomerStore from 'Shared/store/renterCustomer'
import orderActionLogger from 'Shared/orderActionLogger'

import toAggregatorArticles from 'Aggregator/shared/toAggregatorArticles'
import createTownStore from 'Aggregator/store/town'
import {getArticle, getOrdersUrl, getStateUrl} from 'Aggregator/lib/order'
import {getAvailabilityUrl} from 'Aggregator/shared/availability'

Vue.use(Vuex)

const townStore = createTownStore(
  window.towns,
  Vue.cookie.get('town_id')
)

const store = new Vuex.Store(
  {
    strict: window.environment === 'development',
    state,
    actions,
    mutations,
    getters,
    plugins: [orderActionLogger],
    modules: {
      articles: createArticlesStore(
        (article) => article.renter_id,
        toAggregatorArticles
      ),
      customer: createCustomerStore(
        '/api/aggregator_customer.json',
        '/api/aggregator_customers/sign_out.json',
        '/api/aggregator_customer.json'
      ),
      renterCustomer: createRenterCustomerStore(
        (renterId) => `/api/renters/${renterId}/customer`
      ),
      availability: createAvailabilityStore(
        getAvailabilityUrl
      ),
      town: townStore
    }
  }
)

store.registerModule('order', createOrderStore(
  null,
  (id) => {
    return state.rentersById[id]
  },
  getArticle,
  getOrdersUrl,
  (articleId: number, renterId: number) => {
    return getAvailableForPeriodOrTotal(
      getArticle(articleId, renterId),
      renterId,
      store
    )
  },
  getStateUrl,
  true
))

export default store
