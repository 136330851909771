<template lang="pug">
  .content(v-infinite-scroll="loadMore" infinite-scroll-disabled="isInfiniteScrollDisabled" infinite-scroll-distance="3000")
    slot(name="left-sidebar")
    .catalog-and-heading-col(:class="{wide: isWide}")
      slot(name="above-title")
      .page-heading(style="display: flex; align-items: last baseline;")
        slot(name="title")
        .tools(style='display: flex')
          agv-ordering-selector(v-if='showOrdering'
            :sort-column.sync='sortColumn'
            :sort-direction.sync='sortDirection'
            :ordering-criteria="orderingCriteria"
            @changed="onOrderingChanged")
          .spreader
          .article-count(v-show="articles.length")
            | Показано {{filteredArticlesCount | decline(['изделие', 'изделия', 'изделий'])}}
      div(style="display: flex; flex-direction: column; width: 100%; margin-top: -26px; margin-bottom: 10px;")
        slot(name="warning")
      .catalog-container
        .catalog(v-show='showArticles || showBanner' :class="{'catalog-with-ads': doLimitedArticlesHaveAds}" style="height: fit-content")
          agv-ad-banner(v-if="showBanner" style="margin-bottom: 40px" :show-only-new="showOnlyNewBanners")
          component(:is="article.isMagnet ? 'agv-magnet' : 'agv-article'"
            v-for="(article, index) in articlesWithMagnet"
            :key="article.renter_id + ':' + article.id"
            :article="article"
            :show-discount="showDiscounts"
            :town-id="article.town_id"
            :renter="getRenterById(article.renter_id)"
            :show-rating="true"
            :is-advertising="getIsAdvertising(article, index)"
            :show-like-button="true"
            :show-created-at="showCreatedAt")
        slot(name="right-sidebar")
</template>

<script>
  import _ from 'lodash'
  import Vue from 'vue'

  import CheckMark from 'Shared/sprites/check.svg'
  import Cross from 'Aggregator/sprites/cross.svg'
  import IconCrossSmall from 'Shared/sprites/icon-cross-small.svg'

  import AgvArticle from 'Aggregator/components/agv-article'
  import AgvOrderingSelector from 'Aggregator/components/agv-ordering-selector'
  import AgvRating from 'Aggregator/components/agv-rating'
  import AgvArticlesList from 'Aggregator/components/agv-articles-list'
  import AgvMagnet from 'Aggregator/components/agv-magnet'
  import AgvAdBanner from "Aggregator/components/agv-ad-banner.vue";

  let ORDERING_CRITERIA = [
    {name: 'price_with_discount', description: 'по цене', defaultDirection: 'asc'},
    {name: 'total_available', description: 'по запасу', defaultDirection: 'desc'},
    {name: 'size', description: 'по размеру', defaultDirection: 'desc'}
  ]

  export default {
    props: {
      articles: {
        type: Array,
        required: true
      },
      filteredArticlesCount: {
        type: Number,
        required: true
      },
      isLoadingArticles: {
        type: Boolean,
        required: true
      },
      showOrdering: {
        type: Boolean,
        required: true
      },
      showArticles: {
        type: Boolean,
        required: true
      },
      getIsAdvertising: {
        type: Function,
        required: true
      },
      renter: Object,
      magnetArticle: Object,
      showDiscounts: Boolean,
      showCreatedAt: Boolean,
      showBanner: Boolean,
      showOnlyNewBanners: Boolean,
      isWide: {
        type: Boolean
      }
    },
    name: "agv-articles-list",
    data() {
      return {
        sortColumn: null,
        sortDirection: null,
        orderingCriteria: ORDERING_CRITERIA
      }
    },
    components: {
      AgvAdBanner,
      AgvMagnet,
      AgvRating,
      AgvArticle,
      AgvOrderingSelector,
      CheckMark,
      Cross,
      IconCrossSmall
    },
    created() {
      this.sortColumn = this.defaultSortColumn
      this.sortDirection = this.defaultSortDirection
    },
    computed: {
      isInfiniteScrollDisabled() {
        return this.articles.length < 42 ||
          this.isLoadingArticles ||
          (this.articles.length >= this.filteredArticlesCount)
      },
      doLimitedArticlesHaveAds() {
        return !!(_.find(_.take(this.articles, 4), a => a.ad_copy))
      },
      articlesWithMagnet() {
        if (!this.magnetArticle || this.articles.length < 9) {
          return this.articles
        }

        let result = _.clone(this.articles)
        result.splice(6, 0, this.magnetArticle)

        return result
      }
    },
    methods: {
      resetFiltersAndSorting() {
        this.sortColumn = null
        this.sortDirection = null
      },
      loadMore() {
        this.$emit('loadMore')
      },
      onOrderingChanged() {
        const ordering =  this.sortColumn && this.sortDirection  ? this.sortColumn + ' ' + this.sortDirection : null
        this.$emit('orderingChanged', ordering)
      },
      getRenterById(renterId) {
        return this.$store.state.rentersById[renterId]
      },
      orderBy: _.orderBy,
    }
  }
</script>

<style scoped>
  .content {
    display: flex;
    align-items: baseline;
  }

  .catalog-container {
    display: flex;
    justify-content: space-between;
  }

  .catalog-and-heading-col {
    flex-basis: 80%;
  }

  .catalog-and-heading-col.wide {
    flex-basis: 100%;
  }

  .catalog {
    display: grid;
  }

  .catalog {
    grid-template-columns: repeat(3, 1fr);
  }

  .catalog-and-heading-col.wide .catalog {
    grid-template-columns: repeat(4, 1fr);
  }

  .ag-article {
    flex-basis: 33.3%;
  }

  .catalog-and-heading-col.wide .ag-article {
    flex-basis: 25%;
  }

  .article-count {
    width: 185px;
    font-size: 14px;
    color: #949494;
    white-space: nowrap;
  }

  .catalog-with-ads {
    padding-top: 37px;
  }

  .order-count {
    margin-right: 20px;
    font-size: 14px;
    color: #4a4a4a;
  }

  .order-count::before {
    content: '★';
    color: #f6ac18;
    display: inline-block;
    margin-right: 5px;
  }

  .spreader {
    flex-grow: 1;
  }
</style>
