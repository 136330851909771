import catalogPage from './catalog'

export default {
  type: 'search',
  getTitle(vue) {
    return vue.searchTerms
  },
  getPath(vue) {
    return `/api/articles/${vue.town.id}/search`
  },
  getDataOptionalQueryParams(vue) {
    return {search_terms: [vue.searchTerms]}
  },
  getCurrentKindTags(vue) {
    return catalogPage.getCurrentKindTags(vue)
  },
  toggleTag(vue, tag) {
    return catalogPage.toggleTag(vue, tag)
  },
  replaceInPath(vue, key, value) {
    return catalogPage.replaceInPath(vue, key, value)
  },
}
