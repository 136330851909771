import _ from 'lodash'
import qs from 'querystring'

export default {
  type: 'renter',
  getTitle(vue) {
    return _.get(vue.renter, 'short_commercial_name') || ''
  },
  getPath(vue) {
    return `/api/articles/by_renter_id_and_town_id/${vue.renter_id}/${vue.town.id}`
  },
  getDataOptionalQueryParams(vue) {
    let tagIdsByAggregatorTagTypeId = _.groupBy(
      vue.tag_ids,
      (id) => {
        let tag = _.find(vue.currentKindTags, t => t.id === id)
        return 'tag_ids_' + tag.aggregator_tag_type_id
      }
    )

    let possibleTagTypesId = _.uniq(_.map(vue.currentKindTags, 'aggregator_tag_type_id'))

    _.each(possibleTagTypesId, (ttId) => {
      let tagTypeParamName = 'tag_ids_' + ttId
      if (!tagIdsByAggregatorTagTypeId[tagTypeParamName]) {
        tagIdsByAggregatorTagTypeId[tagTypeParamName] = []
      }
    })

    return _.merge(
      tagIdsByAggregatorTagTypeId,
      {
        kind_id: vue.$route.params.kind_id
      }
    )
  },
  getCurrentKindTags(vue) {
    if (!vue.kind) {
      return []
    }

    return vue.tagsByKindId[vue.kind.id]
  },
  toggleTag(vue, tag) {
    let tagIds = _.clone(vue.checkedTagIds)
    tagIds[tag.id] = !vue.checkedTagIds[tag.id]
    vue.$router.push(vue.replaceInPath('tag_ids', vue.tagStringFromHash(tagIds)))
  },
  replaceInPath(vue, key, value) {
    const colorString = vue.tagStringFromHash(vue.checkedColors)
    let currentValues = {colors: colorString}
    currentValues[key] = value
    currentValues = _.pickBy(currentValues, _.identity)
    return vue.$route.path + '?' + qs.stringify(currentValues)
  },
  toggleKind(vue, kind) {
    let kindIds = _.clone(vue.checkedKindIds)
    kindIds[kind.id] = !vue.checkedKindIds[kind.id]
    vue.$router.push(vue.replaceInPath('kind_ids', vue.tagStringFromHash(kindIds)))
  }
}
