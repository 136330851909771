import Mustache from 'mustache'
import _ from 'lodash'

function findTemplate(path, templates) {
  const template = _.find(
    templates,
    t => {
      return !!path.match(t.regex)
    }
  )
  return _.get(template, 'template', 'Пифакит')
}

export function renderMeta(path, templates, state) {
  return Mustache.render(
    findTemplate(path, templates),
    state
  )
}
