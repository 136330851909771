<template lang="pug">
  .agv-report-renter
    el-dialog(:title="isInThanksMode ? '' : title" width="520px" :visible.sync="isModalVisible" top="7vh")
      div(v-if="isInThanksMode")
        | Принято, разберемся. Спасибо за небезразличие!
      div(v-else)
        el-checkbox(v-model="didntAnswer")
          | Не взял трубку
        el-checkbox(v-model="wasRude")
          | Грубо разговаривал
        el-checkbox(v-model="refusedOrder")
          | Отказал в размещении заказа
        el-checkbox(v-model="didntFollowRules")
          | Противоречил условиям работы
        el-input(type="textarea" :autosize="{ minRows: 2, maxRows: 6}" placeholder="Своими словами" v-model="inOwnWords")
        br
        p
          | Оставьте имя и телефон, если хотите, чтобы мы могли с вами связаться
        .contacts-container
          el-input(type="text" placeholder="Имя" v-model="name")
          el-input(type="text" placeholder="Телефон" v-model="phone")
        .submit-container
          el-button(type="primary" @click="submit")
            | Отправить
    el-button.report-btn(type="text" @click="onClickReport")
      | Пожаловаться
</template>

<script>
  import axios from 'axios'

  export default {
    props: {
      renter: Object
    },
    data() {
      return {
        isInThanksMode: false,
        isModalVisible: false,
        didntAnswer: false,
        wasRude: false,
        refusedOrder: false,
        didntFollowRules: false,
        inOwnWords: null,
        name: null,
        phone: null
      }
    },
    computed: {
      title() {
        return `Расскажите о своем опыте общения с прокатчиком «${this.renter.short_commercial_name}»`
      },
      problemDescription() {
        return _.compact([
          this.didntAnswer ? 'не взял трубку' : null,
          this.wasRude ? 'грубо разговаривал' : null,
          this.refusedOrder ? 'отказал в размещении заказа' : null,
          this.didntFollowRules ? 'противоречил условиям работы' : null,
          this.inOwnWords
        ]).join(', ')
      }
    },
    methods: {
      onClickReport() {
        this.isModalVisible = true
        this.isInThanksMode = false
      },
      submit() {
        axios.post('/api/reports', {
          renter_id: this.renter.id,
          problem: this.problemDescription,
          name: this.name,
          phone: this.phone
        }).then(()=>{
          this.isInThanksMode = true
        })
      }
    },
    name: "agv-report-renter"
  }
</script>

<style scoped>
  .el-checkbox, .el-textarea {
    display: block;
    margin-bottom: 15px;
    font-weight: normal;
  }

  .el-textarea {
    margin-top: 20px;
  }

  .contacts-container {
    display: flex;
    justify-content: space-between;
  }

  .submit-container {
    margin-top: 40px;
    text-align: left;
  }

  .el-input {
    width: 48%;
  }

  .el-input, .el-textarea >>> textarea {
    border-radius: 1px;
  }

  .agv-report-renter >>> .el-dialog__title {
    font-size: 18px;
  }

  .el-button.report-btn {
    padding: 4px 8px;
    border-radius: 2px;
    border: 1px solid lightgray;
    font-size: 11px;
    color: black !important;
  }

  .el-button.report-btn:hover {
    background: #eee;
    border: 1px solid #eee;
  }
</style>
