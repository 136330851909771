import _ from 'lodash'
import qs from 'querystring'

export default {
  type: 'list',
  getTitle(vue) {
    console.log(vue.isLikedPage)
    if (vue.isFavoritesPage) {
      return 'Уже заказывали'
    }

    if (vue.isPopularArticlesPage) {
      return 'Топ-200 популярных изделий'
    }

    if (vue.isLikedPage) {
      return 'Понравилось'
    }

    if (vue.isNewArticlesPage) {
      return 'Новинки'
    }

    return 'Все изделия'
  },
  getPath(vue) {
    if (vue.isFavoritesPage) {
      return `/api/aggregator_customer/favorite_articles/${vue.town.id}`
    }

    if (vue.isPopularArticlesPage) {
      return '/api/articles/popular'
    }

    if (vue.isLikedPage) {
      return `/api/aggregator_customer/liked_articles/${vue.town.id}`
    }

    if (vue.isNewArticlesPage) {
      return `/api/articles/${vue.town.id}/new_articles`
    }

    return `/api/articles/${vue.town.id}/all`
  },
  getDataOptionalQueryParams(vue) {
    return {kind_ids: vue.kind_ids}
  },
  replaceInPath(vue, key, value) {
    const kindString = vue.tagStringFromHash(vue.checkedKindIds)
    const colorString = vue.tagStringFromHash(vue.checkedColors)
    let currentValues = {kind_ids: kindString, colors: colorString}
    currentValues[key] = value
    currentValues = _.pickBy(currentValues, _.identity)
    return vue.$route.path + '?' + qs.stringify(currentValues)
  },
  toggleKind(vue, kind) {
    let kindIds = _.clone(vue.checkedKindIds)
    kindIds[kind.id] = !vue.checkedKindIds[kind.id]
    vue.$router.push(vue.replaceInPath('kind_ids', vue.tagStringFromHash(kindIds)))
  }
}
