<template lang="pug">
  agv-articles-list(:articles="articles"
    v-show="loaded"
    :filtered-articles-count="meta.filtered_count"
    :renter="page.type === 'renter' ? renter : null"
    :magnetArticle="showMagnet ? magnetArticle : null"
    :show-discounts="isCustomerProfessional"
    :is-wide="page.type === 'renter'"
    :get-is-advertising="isAdvertising"
    :is-loading-articles="isLoadingArticles"
    :show-ordering="!!articles.length && !(isPopularArticlesPage || isFavoritesPage || isLikedPage || isNewArticlesPage || (page.type === 'ad_banner'))"
    :show-articles="!!articles.length"
    :show-created-at="isNewArticlesPage"
    :show-banner="(page.type === 'catalog') || isLikedPage || isNewArticlesPage"
    :show-only-new-banners="isNewArticlesPage"
    ref="agvArticlesList"
    @orderingChanged="onOrderingChanged"
    @loadMore="onLoadMore")
    div(slot="title")
      h2(v-if="page.type === 'renter'" style="font-size:18px; margin-top: 10px; margin-right: 20px;")
        | {{kind.name | capitalize}}
      h1.page-title(v-else)
        span.title
          | {{title | capitalize}}
    div(slot="above-title" v-if="page.type === 'renter'")
      h1.page-title.page-title_top
        router-link.title(:to="`/${town_id}/renter/${renter_id}`" style="color: black;")
          | {{title | capitalize}}
        agv-rating(:rating="renter.avg_rating" :rating-count="renter.rating_count")
      .back-link
        agv-breadcrumbs(style="margin-top: 14px; margin-bottom: 20px;" :show-arrow="true" :items="[{name: 'Назад к категориям', url: `/${town_id}/renter/${renter_id}`}]")
    agv-side-navigation(slot="left-sidebar"
      v-if="page.type !== 'renter' && !isPopularArticlesPage"
      :categories="navCategories"
      :show-favorites="customerHasFavorites"
      :show-liked="customerHasLikedArticles || customerHasLikedAdBanners")
    div(v-if="!articles.length && page.type === 'search'" slot="warning")
      .not-found-warning
        | Ничего не нашлось.
        br
        br
        | Попробуйте ввести синоним или обобщающее слово.
        | Не сработало — значит, нужных изделий на Пифаките пока нет.
        br
        | Если найдете их за пределами Пифакита, посоветуйте хозяину
        |
        a(href='/signup')
          | разместиться
        |
        | и получать заказы.
    .agv-articles-sidebar.sidebar-wrapper.noselect(v-if="articles.length" slot="right-sidebar")
      agv-filter-remove-button(@click='clearFilters'
        :style="{visibility: anyFilters ? 'visible' : 'hidden'}")
      .filter-block(v-if='availableColors.length' style='margin-bottom: 0; padding-bottom: 4px;')
        .filter-block-title Цвет
        agv-color-filter(:color-options="availableColors"
          :active-colors="activeColors"
          :checked-colors="checkedColors"
          @toggle="toggleColor"
          :is-compact="true")
      div(v-if="page.type === 'catalog' || page.type === 'renter'")
        agv-filter-block(v-for="tagType in availableAggregatorTagTypesWithTags"
          :key="tagType.id"
          :name="tagType.name"
          :tags="tagType.availableTags"
          :active-tag-ids="tagType.activeTagIds"
          :checked-tag-ids="checkedTagIds"
          :show-thumbnails="tagType.show_thumbnails"
          @toggle="toggleTag")
      div(v-if="page.type === 'list'")
        agv-filter-block(name="Категория"
          :tags="availableKinds"
          :active-tag-ids="activeKindIds"
          :checked-tag-ids="checkedKindIds"
          :show-thumbnails="false"
          @toggle="toggleKind")
</template>

<script>
import axios from 'axios'
import qs from 'querystring'

import Vue from 'vue'

import AgvFilterRemoveButton from 'Aggregator/components/agv-filter-remove-button'
import AgvColorFilter from 'Aggregator/components/agv-color-filter'
import AgvFilterBlock from 'Aggregator/components/agv-filter-block'
import AgvArticlesList from 'Aggregator/components/agv-articles-list'
import AgvSideNavigation from 'Aggregator/components/agv-side-navigation'
import {renderMetaForComponent} from 'Aggregator/meta'
import AgvBreadcrumbs from "Aggregator/components/agv-breadcrumbs.vue";
import AgvRating from "Aggregator/components/agv-rating.vue";
import _ from "lodash";

export default Vue.extend({
  name: 'page-agv-list',
  components: {
    AgvBreadcrumbs,
    AgvFilterBlock,
    AgvArticlesList,
    AgvColorFilter,
    AgvFilterRemoveButton,
    AgvSideNavigation,
    AgvRating,
  },
  metaInfo() {
    return {
      titleTemplate: `%s | Пифакит`,
      title: this.metaTitle,
      meta: [{vmid: 'description', name: 'description', content: this.metaDescription}]
    }
  },
  data() {
    return {
      loaded: false,
      articles: [],
      meta: {colors: [], tag_ids: [], filtered_count: 0},
      isLoadingArticles: true,
      kindsById: _.keyBy(this.$store.state.kinds, 'id'),
      aggregatorTagTypes: this.$store.state.aggregatorTagTypes,
      tagsByKindId: this.$store.state.tagsByKindId,
      offset: 0,
      ordering: null,
      magnetArticle: null,
      adBanner: null
    }
  },
  computed: {
    town_id() {
      return this.$route.params.town_id
    },
    town() {
      return this.$store.getters.town
    },
    metaTitle() {
      return renderMetaForComponent(this, 'title')
    },
    metaDescription() {
      return renderMetaForComponent(this, 'description')
    },
    metaState() {
      return {
        town: this.town,
        kind: this.kind
      }
    },
    checkedKindIds() {
      return this.tagHashFromList(this.kind_ids) || {}
    },
    customerHasFavorites() {
      return _.get(this.$store.state.customer.current, 'has_favorites')
    },
    customerHasLikedArticles() {
      return _.get(this.$store.state.customer.current, 'liked_articles_count') > 0
    },
    customerHasLikedAdBanners() {
      return _.get(this.$store.state.customer.current, 'liked_ad_banners_count') > 0
    },
    navCategories() {
      return this.$store.getters.navCategories
    },
    checkedTagIds() {
      return this.tagHashFromList(this.tag_ids) || {}
    },
    checkedColors() {
      return this.tagHashFromList(this.colors) || {}
    },
    activeColors() {
      if (!this.meta.filter_except_colors) {
        return this.meta.colors
      }

      return this.meta.filter_except_colors
    },
    availableTags() {
      return _.filter(this.currentKindTags, t => _.includes(this.meta.tag_ids, t.id))
    },
    availableAggregatorTagTypes() {
      return _.filter(
          this.aggregatorTagTypes,
          tt => _.find(this.availableTags, {aggregator_tag_type_id: tt.id})
      )
    },
    availableAggregatorTagTypesWithTags() {
      return _.map(
          _.orderBy(this.availableAggregatorTagTypes, 'position'),
          tt => {
            let idList = this.meta['filter_except_tag_ids_' + tt.id] || this.meta.tag_ids

            return _.merge({}, tt, {
              availableTags: _.filter(this.availableTags, {aggregator_tag_type_id: tt.id}),
              activeTagIds: _.fromPairs(_.map(idList, id => [id, true]))
            })
          }
      )
    },
    availableColors() {
      return _.filter(window.color_options, (o) => {
        return _.includes(this.meta.colors, o.value)
      })
    },
    kind() {
      if (!(this.kind_id)) {
        return null
      }
      return this.kindsById[this.kind_id]
    },
    navigationMethod() {
      return this.fromSearch ? 'search' : 'browsing'
    },
    dataQueryString() {
      let queryParams = _.merge(
          {colors: this.colors},
          this.dataOptionalQueryParams
      )

      queryParams = _.mapValues(queryParams, (p) => {
        return Array.isArray(p) ? p.join(' ') : p
      })

      if (this.offset) {
        queryParams.offset = this.offset
      }

      if (this.ordering) {
        queryParams.order_by = this.ordering
      }

      return qs.stringify(queryParams)
    },
    dataUrl() {
      return this.path + '?' + this.dataQueryString
    },
    isCustomerProfessional() {
      return !!_.get(this.$store.state.customer.current, 'is_professional')
    },
    showMagnet() {
      return this.page.type === 'catalog' &&
          !_.get(this.$store.state.customer.current, 'id')
    },
    title() {
      return this.page.getTitle(this)
    },
    dataOptionalQueryParams() {
      return this.page.getDataOptionalQueryParams(this)
    },
    path() {
      return this.page.getPath(this)
    },
    renter() {
      return this.$store.state.rentersById[this.renter_id]
    },
    theOnlyCheckedTag() {
      if (this.tag_ids.length !== 1) {
        return null
      }

      return _.find(this.currentKindTags, t => t.id === this.tag_ids[0])
    },
    currentKindTags() {
      return this.page.getCurrentKindTags(this)
    },
    anyFilters() {
      return _.some([this.colors, this.tag_ids, this.kind_ids], arr => arr && arr.length)
    },
    availableKinds() {
      return _.filter(this.$store.state.kinds, k => _.includes(this.meta.kind_ids, k.id))
    },
    activeKindIds() {
      let activeKindIds = this.meta.filter_except_kind_ids || this.meta.kind_ids
      return _.fromPairs(_.map(activeKindIds, kid => [kid, true]))
    }
  },
  methods: {
    replaceInPath(key, value) {
      return this.page.replaceInPath(this, key, value)
    },
    toggleKind(kind) {
      this.page.toggleKind(this, kind)
    },
    toggleTag(tag) {
      this.page.toggleTag(this, tag)
    },
    onOrderingChanged(ordering) {
      this.offset = 0
      this.ordering = ordering
      this.fetchData()
    },
    onLoadMore() {
      this.offset += 42
      this.fetchData()
    },
    tagStringFromHash(tagIdsHash) {
      return _.keys(
          _.pickBy(tagIdsHash, _.identity)
      ).sort().join('+')
    },
    tagHashFromList(tagIdsList) {
      if (!_.get(tagIdsList, 'length')) {
        return {}
      }
      return _.reduce(tagIdsList, (obj, id) => {
        obj[id] = true
        return obj
      }, {})
    },
    isAdvertising(article, index) {
      if (!this.category) {
        return false
      }

      return index < 3 && !!(
          this.navigationMethod === 'browsing' && article.browsing_ad_position ||
          this.navigationMethod === 'search' && article.search_ad_position
      )
    },
    toggleColor(color) {
      let colors = _.clone(this.checkedColors)
      colors[color] = !this.checkedColors[color]
      this.$router.push(this.replaceInPath('colors', this.tagStringFromHash(colors)))
    },
    fetchData() {
      if (this.ad_banner_id) {
        axios.get(`/api/ab/${this.ad_banner_id}`).then(({data}) => {
          this.adBanner = data
          console.log(this.adBanner)
        })
      }

      this.isLoadingArticles = true

      const resetArticles = this.offset === 0
      axios.get(this.dataUrl).then(({data: {articles: loadedArticles, meta}}) => {
        if (resetArticles) {
          this.articles = loadedArticles
          this.magnetArticle = null
        } else {
          this.articles = this.articles.concat(loadedArticles)
        }
        Vue.set(this, 'meta', meta)
        this.$emit('loaded')
        this.isLoadingArticles = false
        this.loaded = true

        if (resetArticles && this.page.type === 'catalog') {
          const firstTag = _.first(
              _.orderBy(
                  _.filter(this.currentKindTags, t => _.includes(this.meta.tag_ids, t.id)),
                  'compound_position'
              )
          )
          if (firstTag) {
            axios.get(
                `/api/articles/${this.town_id}/magnet/${this.kind_id}?tag_ids_1=${firstTag.id}`
            ).then(({data: article}) => {
              this.magnetArticle = _.merge({isMagnet: true}, article)
            })
          } else {
            this.magnetArticle = _.merge({isMagnet: true}, _.first(_.orderBy(this.articles, 'usefulness', 'desc')))
          }
        }
      })
    },
    clearFilters() {
      this.$router.push(this.$route.path)
    },
  },
  props: {
    fromSearch: Boolean,
    colors: Array,
    kind_ids: Array,
    isFavoritesPage: Boolean,
    isPopularArticlesPage: Boolean,
    isLikedPage: Boolean,
    isNewArticlesPage: Boolean,
    renter_id: String,
    ad_banner_id: String,
    tag_ids: Array,
    kind_id: String,
    searchTerms: String,
    page: {
      type: Object,
      required: true
    }
  },
  watch: {
    $route(to) {
      if (!this.articles.length) {
        this.loaded = false
      }
      this.offset = 0
      this.ordering = null
      this.$refs.agvArticlesList.sortColumn = null
      this.$refs.agvArticlesList.sortDirection = null
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  },
})
</script>

<style scoped>
.not-found-warning {
  color: grey;
  display: block;
  font-size: 14px;
  margin-left: 6px;
}

.sidebar-wrapper {
  position: relative;
  margin-left: 20px;
}

.btn-filter-remove {
  position: absolute;
  top: -29px;
  right: 0;
}

.agv-filter-block {
  margin-bottom: 40px;
  margin-top: 10px;
}

.back-link {
  font-size: 14px;
  display: inline-block;
}

.title {
  margin-right: 20px;
}

.page-title >>> .agv-rating {
  font-size: 12px;
  display: inline-block;
  margin-top: 14px;
  margin-right: 20px;
}

.page-title >>> .agv-star-rating {
  margin-right: 0.4em;
  display: inline-block;
}

.page-title >>> .rating-count {
  font-weight: normal;
  display: inline-block;
}
</style>
