import _ from 'lodash'
import qs from 'querystring'

export default {
  type: 'ad_banner',
  getTitle(vue) {
    const renterName = vue.adBanner?.renter.short_commercial_name
    if (vue.adBanner?.is_new) {
      return `Новинка от ${renterName}`
    } else {
      return renterName || ''
    }
  },
  getPath(vue) {
    return `/api/articles/by_ab_id/${vue.ad_banner_id}`
  },
  getDataOptionalQueryParams(vue) {
    return {kind_ids: vue.kind_ids}
  },
  replaceInPath(vue, key, value) {
    const kindString = vue.tagStringFromHash(vue.checkedKindIds)
    const colorString = vue.tagStringFromHash(vue.checkedColors)
    let currentValues = {kind_ids: kindString, colors: colorString}
    currentValues[key] = value
    currentValues = _.pickBy(currentValues, _.identity)
    return vue.$route.path + '?' + qs.stringify(currentValues)
  },
  toggleKind(vue, kind) {
    let kindIds = _.clone(vue.checkedKindIds)
    kindIds[kind.id] = !vue.checkedKindIds[kind.id]
    vue.$router.push(vue.replaceInPath('kind_ids', vue.tagStringFromHash(kindIds)))
  }
}
