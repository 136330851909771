<template lang="pug">
  router-view(:renters="renters" :categories="categories" :town="town")
</template>

<script>
  import _ from 'lodash'
  import {renderMetaForComponent} from 'Aggregator/meta'

  export default {
    props: {
      renters: Array
    },
    data() {
      return {
        categories: []
      }
    },
    metaInfo() {
      return {
        titleTemplate: `%s | Пифакит`,
        title: this.metaTitle,
        meta: [{vmid: 'description', name: 'description', content: this.metaDescription}]
      }
    },
    computed: {
      metaState() {
        return {
          town: this.town
        }
      },
      metaTitle() {
        return renderMetaForComponent(this, 'title')
      },
      metaDescription() {
        return renderMetaForComponent(this, 'description')
      },
      town() {
        return this.$store.getters.town
      },
      orderedCategories() {
        return _.sortBy(this.categories, 'position', 'asc')
      }
    },
    beforeRouteUpdate(to, from, next) {
      next()
    },
    name: 'agv-content-block'
  }
</script>

<style scoped>
  .router-link-active {
    color: black;
    border-bottom: 1px solid black;
  }
</style>
