import Vue from 'vue'
import _ from 'lodash'
import VueCookie from 'vue-cookie'
import Storage from 'vue-web-storage'

Vue.use(VueCookie)
Vue.use(Storage, {
  prefix: 'aggregator.'
})

export default {
  PRIVILEGED_TOWNS: ['Москва', 'Санкт-Петербург', 'Нижний Новгород', 'Екатеринбург'],
  aggregatorCustomer: {},
  articlePreviews: null,
  availableForPeriod: {},
  availableKindIdsByTownId: {},
  banners: window.banners,
  categories: window.categories,
  categoriesById: _.keyBy(window.categories, 'id'),
  comparedArticleIdsByRenter: Vue.$localStorage.get('comparedArticleIdsByRenter') || {},
  currentArticles: [],
  isLoggedInToDashboard: VueCookie.get('is_logged_in_to_dashboard') === 'true',
  kindCountsByIdByTownId: {},
  kinds: window.kinds,
  tagsByKindId: _.groupBy(window.tags, 'aggregator_kind_id'),
  aggregatorTagTypes: window.aggregator_tag_types,
  rentersById: _.keyBy(window.renters, 'id'),
  savedLogin: VueCookie.get('agl2'),
  seo_templates: window.seo_templates,
  stateByRenterId: {},
  rentPeriod: {acquisition: null, returning: null},
  visitorsNow: window.visitors_now,
  pifakit_service: window.pifakit_service,
  isOrderPlacing: false,
  isOrderCancelling: false,
  isOrderSaving: false,
}
