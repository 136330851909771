// @flow

import _ from 'lodash'

export default function createStore(
  towns: Array<{ name: string }>
) {

  return {
    namespaced: true,
    state: {
      towns: towns,
      townsById: _.keyBy(towns, 'id')
    }
  }
}
