<template lang="pug">
  .agv-filter-block.filter-block
    .filter-block-title
      | {{name}}
    agv-tag-ul.important-tags(:tags="orderedTags"
      :active-tag-ids='activeTagIds'
      :checked-tag-ids='checkedTagIds'
      :show-thumbnails='showThumbnails'
      @toggle="onToggle")
    div(v-if='alwaysShownTags.length !== tags.length && !showTagsAsOneList')
      .show-more-tags-btn(v-if='areNonImportantTagsShown' @click="areNonImportantTagsShown = false")
        | Свернуть
      .show-more-tags-btn(v-else @click="areNonImportantTagsShown = true")
        | Показать все
</template>

<script>
  import _ from 'lodash'

  import AgvTagUl from 'Aggregator/components/agv-tag-ul'

  function isImportantOrChecked(t, checkedTagIds) {
    return !!(t.position || checkedTagIds[t.id])
  }

  export default {
    name: "agv-filter-block",
    data() {
      return {
        areNonImportantTagsShown: false
      }
    },
    props: {
      name: {
        type: String,
        required: true
      },
      tags: {
        type: Array,
        required: true
      },
      activeTagIds: {
        type: Object,
        required: true
      },
      checkedTagIds: {
        type: Object,
        required: true
      },
      showThumbnails: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      onToggle(arg) {
        this.$emit('toggle', arg)
      },
      orderBy: _.orderBy
    },
    components: {AgvTagUl},
    computed: {
      orderedTags() {
        return _.orderBy(
          this.areNonImportantTagsShown || this.showTagsAsOneList ? this.tags : this.alwaysShownTags,
          ['compound_position', 'position', 'name']
        )
      },
      showTagsAsOneList() {
        return this.alwaysShownTags.length < 3
      },
      alwaysShownTags() {
        return _.filter(this.tags, t => isImportantOrChecked(t, this.checkedTagIds))
      },
    }
  }
</script>

<style scoped>
  .filter-block-title {
    margin-bottom: 15px;
  }

  .important-tags {
    margin-top: 12px;
  }

  .show-more-tags-btn {
    font-size: 13px;
    color: #004ba5;
    line-height: 19px;
    cursor: pointer;
    margin-top: 8px;
  }
</style>
