<template lang="pug">
  .agv-ri-discount
    | {{percentage(renter.rent_discount)}}
    span(v-if="renter.has_progressive_discount")
      | –{{percentage(renter.rent_discount) + 10}}
    | %
    |
    | на аренду
    div(v-if='renter.is_delivery_offered')
      | {{renter.service_discount | percentage}} на доставку
</template>

<script>
  export default {
    name: 'agv-ri-discount',
    props: {
      renter: Object
    },
    methods: {
      percentage(value) {
        return Math.round(value * 100)
      }
    }
  }
</script>

<style scoped>

</style>
