<template lang="pug">
  svg(width='23' height='28' xmlns='http://www.w3.org/2000/svg')
    text.quantity(v-if='quantity' text-anchor="middle" x="19" y="24")
      | {{quantity}}
    icon-comparison
</template>

<script>
  import IconComparison from 'Aggregator/sprites/icon-comparison.svg'

  export default {
    name: 'agv-icon-comparison',
    props: {
      quantity: Number
    },
    components: {IconComparison}
  }
</script>

<style scoped>
  .quantity {
    font-size: 12px;
    fill: #F6A623;
  }
</style>
