import _ from 'lodash'

export function getNavCategories(categories, kinds, availableKindIds) {
  if (!availableKindIds) {
    return null
  }

  const navKinds = _.filter(kinds, k => _.includes(availableKindIds, k.id))
  const navCategories = _.filter(categories, c => _.find(navKinds, k => k.aggregator_category_id === c.id))

  return _.map(navCategories, c => {
    return _.merge({
        kinds: _.filter(navKinds, k => k.aggregator_category_id === c.id)
      },
      c
    )
  })
}
