import Vue from 'vue'
import Storage from 'vue-web-storage'

Vue.use(Storage, {
  prefix: 'aggregator.'
})

export default {
  setSavedLogin(state, newLogin) {
    Vue.set(state, 'savedLogin', newLogin)
  },
  setKindCountsById(state, {townId, kindCountsById}) {
    Vue.set(state.availableKindIdsByTownId, townId, _.map(_.keys(kindCountsById), id => parseInt(id)))
    Vue.set(state.kindCountsByIdByTownId, townId, kindCountsById)
  },
  setAvailableForPeriod(state, value) {
    state.availableForPeriod = value
  },
  setRentPeriod(state, range) {
    if (!range) {
      state.availableForPeriod = {}
    }
    state.range = range
  },
  addArticleToComparison(state, {articleId, renterId}) {
    Vue.set(state, 'comparedArticleIdsByRenter', Vue.$localStorage.get('comparedArticleIdsByRenter') || {})

    if (!state.comparedArticleIdsByRenter[renterId]) {
      Vue.set(state.comparedArticleIdsByRenter, renterId, [])
    }

    const articleIds = state.comparedArticleIdsByRenter[renterId]

    if (!_.includes(articleIds, articleId)) {
      articleIds.push(articleId)
    }

    Vue.$localStorage.set('comparedArticleIdsByRenter', state.comparedArticleIdsByRenter)
  },
  removeArticleFromComparison(state, {articleId, renterId}) {
    if (!state.comparedArticleIdsByRenter[renterId]) {
      return
    }

    const articleIds = state.comparedArticleIdsByRenter[renterId]

    if (_.includes(articleIds, articleId)) {
      let newArticleIds = _.without(articleIds, articleId)

      if (_.isEmpty(newArticleIds)) {
        Vue.delete(state.comparedArticleIdsByRenter, renterId)
      } else {
        Vue.set(state.comparedArticleIdsByRenter, renterId, newArticleIds)
      }
    }

    Vue.$localStorage.set('comparedArticleIdsByRenter', state.comparedArticleIdsByRenter)
  },
  clearComparison(state) {
    state.comparedArticleIdsByRenter = {}

    Vue.$localStorage.set('comparedArticleIdsByRenter', state.comparedArticleIdsByRenter)
  },
  setRenterState(state, {renterState, renterId}) {
    Vue.set(state.stateByRenterId, renterId, renterState)
  },
  setRenter(state, {renterId, renter}) {
    Vue.set(state.rentersById, renterId, _.merge({}, state.rentersById[renterId], renter))
  },
  setArticleThumbnails(state, articleThumbnails) {
    state.articleThumbnails = articleThumbnails
  },
  setIsOrderPlacing(state, value) {
    state.isOrderPlacing = value;
  },
  setIsOrderCancelling(state, value) {
    state.isOrderCancelling = value;
  },
  setIsOrderSaving(state, value) {
    state.isOrderSaving = value;
  }
}
