import { render, staticRenderFns } from "./aggregator.vue?vue&type=template&id=130e674e&scoped=true&lang=pug&"
import script from "./aggregator.vue?vue&type=script&lang=js&"
export * from "./aggregator.vue?vue&type=script&lang=js&"
import style0 from "element-ui/lib/theme-chalk/index.css?vue&type=style&index=0&lang=css&"
import style1 from "./aggregator.vue?vue&type=style&index=1&id=130e674e&scoped=true&lang=css&"
import style2 from "./aggregator.vue?vue&type=style&index=2&id=130e674e&scoped=true&lang=css&"
import style3 from "../shared/styles/back-to-top.css?vue&type=style&index=3&lang=css&"
import style4 from "../shared/styles/reset.css?vue&type=style&index=4&lang=css&"
import style5 from "../shared/styles/common.css?vue&type=style&index=5&lang=css&"
import style6 from "../shared/styles/page.css?vue&type=style&index=6&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130e674e",
  null
  
)

export default component.exports