import _ from 'lodash'
import qs from 'querystring'

export default {
  type: 'catalog',
  getTitle(vue) {
    return (vue.navigationMethod === 'search' ? _.get(vue.theOnlyCheckedTag, 'name_with_kind') : null) ||
      _.get(vue.kind, 'name')
  },
  getPath(vue) {
    return `/api/articles/${vue.town.id}/by_kind_id/${vue.kind_id}`
  },
  getDataOptionalQueryParams(vue) {
    let tagIdsByAggregatorTagTypeId = _.groupBy(
      vue.tag_ids,
      (id) => {
        let tag = _.find(vue.currentKindTags, t => t.id === id)
        return 'tag_ids_' + tag.aggregator_tag_type_id
      }
    )

    let possibleTagTypesId = _.uniq(_.map(vue.currentKindTags, 'aggregator_tag_type_id'))

    _.each(possibleTagTypesId, (ttId) => {
      let tagTypeParamName = 'tag_ids_' + ttId
      if (!tagIdsByAggregatorTagTypeId[tagTypeParamName]) {
        tagIdsByAggregatorTagTypeId[tagTypeParamName] = []
      }
    })

    return tagIdsByAggregatorTagTypeId
  },
  getCurrentKindTags(vue) {
    if (!vue.kind) {
      return []
    }

    return vue.tagsByKindId[vue.kind.id]
  },
  toggleTag(vue, tag) {
    let tagIds = _.clone(vue.checkedTagIds)
    tagIds[tag.id] = !vue.checkedTagIds[tag.id]
    vue.$router.push(vue.replaceInPath('tag_ids', vue.tagStringFromHash(tagIds)))
  },
  replaceInPath(vue, key, value) {
    const tagString = vue.tagStringFromHash(vue.checkedTagIds)
    const colorString = vue.tagStringFromHash(vue.checkedColors)
    let currentValues = {tag_ids: tagString, colors: colorString}
    currentValues[key] = value
    currentValues = _.pickBy(currentValues, _.identity)
    return vue.$route.path + '?' + qs.stringify(currentValues)
  },
}
