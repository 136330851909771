<template lang="pug">
  aside.navigation-col
    .navigation-header
      | Категории
    .agv-side-navigation
      .category
        router-link.kind-name(:to="likedUrl" v-if="showLiked")
          icon-heart.icon-red-heart
          | Понравилось
        router-link.kind-name(:to="favoritesUrl" v-if="showFavorites")
          icon-history.icon-history
          | Уже заказывали
        router-link.kind-name(:to="newArticlesUrl")
          icon-new.icon-new
          | Новинки
      .category(v-for="category in orderByPosition(categories)")
        router-link.kind-name(:to="urlFor(kind)"
        key="category.id"
        v-for="kind in orderByPosition(category.kinds)"
        :class="{selected: isSelected(kind)}")
          | {{kind.name}}
</template>

<script>
  import _ from 'lodash'
  import {urlFor} from '../shared/navSheet'
  import IconHeart from 'Aggregator/sprites/heart.svg'
  import IconHistory from 'Aggregator/sprites/history.svg'
  import IconNew from 'Aggregator/sprites/new.svg'

  export default {
    name: "agv-side-navigation",
    props: {
      categories: Array,
      showFavorites: Boolean,
      showLiked: Boolean
    },
    components: {IconHeart, IconHistory, IconNew},
    computed: {
      townId() {
        return this.$route.params.town_id
      },
      favoritesUrl() {
        return `/${this.townId}/favorites`
      },
      likedUrl() {
        return `/${this.townId}/liked`
      },
      newArticlesUrl() {
        return `/${this.townId}/new`
      }
    },
    methods: {
      orderByPosition(items) {
        return _.orderBy(items, 'position')
      },
      urlFor(kind) {
        return urlFor(kind, this.townId)
      },
      isSelected(kind) {
        return this.$route.path === this.urlFor(kind)
      }
    }
  }
</script>

<style scoped>
  .navigation-col {
    flex-basis: 20%;
  }

  .navigation-header {
    height: 42px;
    font-weight: 500;
    font-size: 18px;
    color: #4a4a4a;
  }

  .agv-side-navigation {
    padding-right: 60px;
  }

  .category {
    padding-bottom: 12px;
    border-bottom: 1px solid #E7E7E7;
    margin-bottom: 13px;
  }

  .category:last-of-type {
    border-bottom: none;
  }

  .kind-name {
    display: block;
    font-size: 14px;
    color: #000000;
    line-height: 38px;
    white-space: nowrap;
    padding-left: 13px;
    margin-left: -13px;
  }

  .kind-name:hover {
    background: #f7f7f7;
    color: #555;
  }

  .kind-name.selected, .router-link-active {
    background: #FFF6DE;
  }

  .icon-red-heart {
    margin-right: 7px;
    top: 1px;
    position: relative;
    width: 15px;
    color: #4a4a4a;
  }

  .icon-history {
    margin-right: 7px;
    top: 2px;
    position: relative;
    width: 16px;
    color: #4a4a4a;
  }

  .icon-new {
    margin-right: 7px;
    top: 2px;
    position: relative;
    width: 16px;
    color: #4a4a4a;
  }
</style>
