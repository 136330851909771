<template lang="pug">
  .agv-ri-cp
    .cp-address(:is="branch.location_url ? 'a' : 'div'" :href="branch.location_url" target="_blank")
      icon-geo-tag.icon-geo-tag(v-if="branch.location_url")
      | {{branch.cp_address}}
    .cp-schedule(style='line-height: 19px')
      span(v-if="branch.freeform_cp_schedule" v-html="newlinesToBrTags(branch.freeform_cp_schedule)")
      span.cp-schedule-time(v-else)
        | {{branch.cp_off_days | cpWorkdaysFromOffDays}},
        |
        | {{branch.cp_schedule | cpSchedule}}.
</template>

<script>
  import IconGeoTag from 'Aggregator/sprites/icon-geo-tag.svg'

  export default {
    name: 'agv-ri-cp',
    props: {
      branch: Object
    },
    components: {IconGeoTag},
    methods: {
      newlinesToBrTags(str) {
        return this.$options.filters.newlinesToBrTags(str)
      }
    }
  }
</script>

<style scoped>
  .icon-geo-tag {
    position: relative;
    top: 3px;
    transform: scale(0.7);
    margin-right: 3px;
    margin-left: -2px;
  }

  .cp-schedule-time {
    white-space: nowrap;
  }

  .cp-address {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

</style>
