<template lang="pug">
  .content
    .order-wrapper(v-if="order && customer && currentRenterState")
      order-main(v-if="order"
      :order='order'
      :renter='renter'
      :show-renter-details='true'
      :branch='branch'
      :customer='customer'
      :articles='articles'
      :categories='categories'
      :is-order-current="isOrderCurrent"
      :is-embedded="false"
      :select-articles-in-dropdown="!!order.id"
      :is-user-admin="false"
      :is-date-disabled-for-order="isDateDisabledForOrder"
      :get-town="getTown"
      :get-article="getArticle"
      :get-article-url="getArticleUrl"
      :get-orders-url="getOrdersUrl"
      :get-order-bill-url="getOrderBillUrl"
      :get-last-wire-transfer-bill-details-url="getLastWireTransferBillDetailsUrl"
      :get-renter-route-url="getRenterRouteUrl"
      :get-customer-bills-url="getCustomerBillsUrl"
      :rules-url="rulesUrl"
      :export-order-url="exportUrl"
      :add-article-url="getRenterRouteUrl(renter)"
      @finishedEditing="onFinishedEditing"
      @cancelled="onCancelled"
      @restored="onRestored"
      @placed="onPlaced"
      @saved="onSaved"
      @updateCustomerBalance="onUpdateCustomerBalance"
      @validationErrors="onValidationErrors")
      agv-order-select(:orders-by-renter-id="ordersForSelector" :get-article="getArticle")
</template>

<script>
  import OrderMain from 'Shared/components/order-main'
  import {
    findOrder,
    getExportUrl,
    getArticle,
    getArticleUrl,
    getOrdersUrl,
    getOrderBillUrl,
    getLastWireTransferBillDetailsUrl,
    getOrdersRouteUrl,
    getCustomerBillsUrl
  } from 'Aggregator/lib/order'
  import AgvOrderSelect from 'Aggregator/components/agv-order-select'
  import store from 'Aggregator/store/index'

  import {
    isDateDisabledForOrder as _isDateDisabledForOrder
  } from 'Shared/order'
  import scrollToTopmostError from 'Shared/lib/scrollToTopmostError'

  export default {
    name: "page-agv-order",
    components: {AgvOrderSelect, OrderMain},
    props: {
      renter_id: String,
      order_id: String,
      town_id: String
    },
    data() {
      return {
        isOrderCurrent: () => {
          return this.order === this.$store.getters['order/getByRenterId'](this.renter_id)
        },
        order: {},
        getArticle,
        getArticleUrl,
        getOrdersUrl,
        getOrderBillUrl,
        getLastWireTransferBillDetailsUrl,
        getCustomerBillsUrl
      }
    },
    computed: {
      rulesUrl() {
        return this.getRenterRouteUrl() + '/rules'
      },
      customer() {
        return _.merge(
          {balance: 0, special_rent_discount: null, special_service_discount: null},
          this.$store.getters.getRenterCustomerByRenterId(this.renter_id),
          this.$store.state.customer.current
        )
      },
      currentRenterState() {
        return _.get(this.$store.state.stateByRenterId, this.renter_id)
      },
      renter() {
        return this.$store.state.rentersById[this.renter_id]
      },
      branch() {
        return _.find(this.currentRenterState.branches, b => b.town_id === parseInt(this.town_id))
      },
      articles() {
        return this.currentRenterState.articles
      },
      categories() {
        return this.currentRenterState.categories
      },
      exportUrl() {
        return getExportUrl(this.order_id, this.renter_id)
      },
      ordersForSelector() {
        if (this.order.id) {
          let obj = {}
          obj[this.renter.id] = this.order
          return obj
        }
        return _.omitBy(this.$store.state.order.ordersByRenterId, o => !o || _.isEmpty(o))
      }
    },
    methods: {
      maybeNavigateToRoot(){
        if (!this.order.id) {
          this.$router.push('/')
        }
      },
      isDateDisabledForOrder(date, order) {
        return _isDateDisabledForOrder(date, order, this.branch)
      },
      getRenterRouteUrl() {
        return `/${this.town_id}/renter/${this.renter_id}`
      },
      getTown() {
        return this.$store.state.town.townsById[this.town_id]
      },
      onPlaced(id) {
        this.$router.push(`/${this.town_id}/renter/${this.renter_id}/order/${id}`)
      },
      onSaved() {
      },
      onFinishedEditing() {
      },
      onCancelled(order) {
        this.order = _.extend({}, this.order, order)
      },
      onRestored(order) {
        this.$store.commit('order/saveOrderToLocalStorage', {order, renterId: this.renter.id})
        this.$router.push(getOrdersRouteUrl(this.renter.id, order.town_id) + '/new')
      },
      onUpdateCustomerBalance() {
        this.$store.dispatch('customer/load')
      },
      onValidationErrors(errors) {
        _.defer(scrollToTopmostError)
      },
      fetchOrder() {
        store.dispatch('loadRenterState', {
          renterId: parseInt(this.renter_id),
          townId: parseInt(this.town_id)
        }).then(() => {
            setTimeout(
              () => {
                const currentOrder = this.$store.getters['order/getByRenterId'](this.renter_id)
                if (
                  parseInt(this.order_id) === currentOrder.id
                ) {
                  this.order = currentOrder
                  return
                }

                if (this.order_id === 'new') {
                  this.$store.commit(
                    'order/setCurrentFromLocalStorage',
                    this.$route.params.town_id
                  )
                  this.order = this.$store.getters['order/getByRenterId'](this.renter_id)
                  return
                }

                findOrder(this.order_id, this.renter_id).then(({data: order}) => {
                  this.order = order
                })
              },
              0
            )
          }
        )
      },
    },
    beforeRouteEnter(to, fr, next) {
      if (!_.find(window.renters, r => r.id === parseInt(to.params.renter_id))) {
        next({path: '/'})
      }
      const loadState = () => {
        store.dispatch('loadArticleThumbnailsByRenterId').then(() => {
          next()
        })
      }
      if (store.state.customer.current.id) {
        loadState()
      } else {
        store.dispatch('customer/load').then(loadState, loadState)
      }
    },
    beforeRouteLeave(to, from, next) {
      store.dispatch('order/revert', {renterId: parseInt(this.renter_id)})
      next()
    },
    watch: {
      renter_id: [
        {
          handler: 'fetchOrder'
        }
      ],
      order_id: [
        {
          handler: 'fetchOrder',
          immediate: true
        }
      ],
      town_id: 'maybeNavigateToRoot'
    },
  }
</script>

<style scoped>
  .order-wrapper {
    display: flex;
  }

  .order-main {
    flex-grow: 1;
  }

  .agv-order-select {
    width: 260px;
    min-width: 260px;
    margin-left: 60px;
  }
</style>
