<template lang="pug">
  div(:style="{display: 'flex', height: (isEmailInBadDomains ? '210' : '90') + 'px'}")
    icon-paper-plane(style="width: 80px; height: 50px; display: inline-block; margin-right: 20px;")
    div(style="line-height: 150%;margin-top: 3px;")
      p
        | Проверьте почту — сейчас туда прилетит ссылка на вход.
      p(style="font-size: 11px; color: #777; line-height: 18px;")
        | Письмо не приходит?
        br
        | Напишите в
        |
        a(href="https://vk.me/pifakit.help" target="_blank")
          | помощь
        | , разберемся.
      p(style="color: #f44" v-if="isEmailInBadDomains")
        | Если вы не можете найти письмо в почтовом ящике, скорее всего оно попало в спам. Мы знаем о проблеме и скоро с ней разберемся.
</template>

<script>
  import IconPaperPlane from 'Aggregator/sprites/send.svg'

  export default {
    name: "agv-login-link-sent",
    components: {IconPaperPlane},
    props: {
      email: String
    },
    computed: {
      isEmailInBadDomains() {
        return false
        if (this.email) {
          return !!this.email.match(/@(mail|bk|list|internet|inbox)\.ru/)
        }
      }
    }
  }
</script>

<style scoped>

</style>
